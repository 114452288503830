import { Scene, PerspectiveCamera, WebGLRenderer, BufferGeometry, Float32BufferAttribute, PointsMaterial, Points } from 'three';

// caution: If multiple ref is rendered, this can cause issue. 
class ThreeBackground {
    constructor() {
        this.scene = null;
        this.camera = null;
        this.renderer = null;
        this.particlesMesh = null;
        this.animationFrameId = null;
        this.interval = null
    }

    init(container) {
        if (this.scene) return; // Already initialized

        this.scene = new Scene();
        this.camera = new PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        this.renderer = new WebGLRenderer({ alpha: true, antialias: true });

        this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.renderer.setPixelRatio(window.devicePixelRatio);
        container.appendChild(this.renderer.domElement);

        this.createParticles();

        this.camera.position.z = 2;

        window.addEventListener('resize', this.handleResize.bind(this));
        // this.interval = setInterval(() => {
        //     this.logState()
        // }, (5000));

        this.animate();
    }

    logState() {
        console.log('Three state: ', {
            camera: this.camera,
            scene: this.scene,
            renderer: this.renderer
        })
    }

    createParticles() {
        const particlesGeometry = new BufferGeometry();
        const particlesCount = 5000;
        const posArray = new Float32Array(particlesCount * 3);

        for (let i = 0; i < particlesCount * 3; i++) {
            posArray[i] = (Math.random() - 0.5) * 5;
        }

        particlesGeometry.setAttribute('position', new Float32BufferAttribute(posArray, 3));

        const particlesMaterial = new PointsMaterial({
            size: 0.005,
            color: '#8080ff'
        });

        this.particlesMesh = new Points(particlesGeometry, particlesMaterial);
        this.scene.add(this.particlesMesh);
    }

    animate() {
        this.animationFrameId = requestAnimationFrame(this.animate.bind(this));
        if (this.particlesMesh) {
            this.particlesMesh.rotation.y += 0.0005;
        }
        this.renderer.render(this.scene, this.camera);
    }

    handleResize() {
        this.camera.aspect = window.innerWidth / window.innerHeight;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(window.innerWidth, window.innerHeight);
    }

    cleanup() {
        if (this.interval) {
            clearInterval(this.interval);
        }
        if (this.animationFrameId) {
            cancelAnimationFrame(this.animationFrameId);
        }

        window.removeEventListener('resize', this.handleResize.bind(this));

        if (this.renderer && this.renderer.domElement && this.renderer.domElement.parentNode) {
            this.renderer.domElement.parentNode.removeChild(this.renderer.domElement);
        }

        // Dispose of Three.js objects
        if (this.particlesMesh) {
            this.scene.remove(this.particlesMesh);
            this.particlesMesh.geometry.dispose();
            this.particlesMesh.material.dispose();
        }

        if (this.renderer) {
            this.renderer.dispose();
        }

        // Reset properties
        this.scene = null;
        this.camera = null;
        this.renderer = null;
        this.particlesMesh = null;
    }
}

export const threeService = new ThreeBackground();