import { Suspense, lazy } from 'react';
import Upload from './Upload';
import { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios'
import { useSelector } from 'react-redux';
import { addNotification } from '@/redux/actions/notificationAction';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// import CategorySelect from './CategorySelect';
// import TaskCategory from './TaskCategory';
const SubjectCategory = lazy(() => import('./CategorySelect'))
const TaskCategory = lazy(() => import('./PostCategory'))
const DatePicker = lazy(() => import('react-datepicker'));
const TagsInput = lazy(() => import('react-tagsinput'))

let BASE_BACKEND_URL = import.meta.env.VITE_BACKEND_URL
export default function CreateTask() {
    let dispatch = useDispatch()
    let navigate = useNavigate()
    let { userId } = useSelector(state => state.user)
    console.log('user id', userId)
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [deadline, setDeadline] = useState(new Date())
    const [subjectCategory, setSubjectCategory] = useState('')
    const [taskCategory, setTaskCategory] = useState('') // category
    const [selectedFiles, setSelectedFiles] = useState([])
    const [tags, setTags] = useState([]); // tags are taskskills
    const [inputTag, setInputTag] = useState('');
    const [error, setError] = useState('')
    console.log('new-post', {selectedFiles})

    async function handleSubmit() {
        setError('')
        if (!(title && description && deadline)) {
            setError('标题，描述，截止日期不可为空')
            return
        }
        if (!userId) {
            setError('no user id. Log in')
            return
        }
        console.log(tags)
        let data = { title, description, deadline, taskCategory }
        console.log('form data', data)

        console.log('files', selectedFiles)
        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        formData.append('userId', userId);

        formData.append('deadline', deadline.toISOString()); // Convert date to string
        // Append each label from taskLabels

        formData.append(`taskCategory`, taskCategory);
        formData.append(`subjectCategory`, subjectCategory);

        // Append files
        selectedFiles.forEach((file) => {
            formData.append(`files`, file, file.name);
        });
        tags.forEach((tag) => {
            formData.append('taskSkills[]', tag)
        })
        let response = await axios.post(`${BASE_BACKEND_URL}/api/new-post`, formData)
        console.log(response.data)
        let taskId = response.data.taskId
        dispatch(addNotification('发送成功！'))
        navigate(`/app/task-list`)
    }

    // 标签变化监听
    const handleChangeTags = (val) => {
        setTags(val)
    }
    // 输入监听
    const handleChangeTagInput = (e) => {
        setInputTag(e)
    }
    // 校验标签
    const validateInputTag = (val) => {
        return val.length <= 12
    }
    // 标签渲染
    const customRenderTag = (props) => {
        let { tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other } = props
        return (
            <span key={key} {...other}>
                {getTagDisplayValue(tag)}
                {!disabled && <a className={classNameRemove} onClick={(e) => onRemove(key)}>x</a>} </span>
        )
    }

    return (
        <div className="flex flex-col py-5 w-[95%] md:w-[80%] mx-auto bg-gradient-to-br from-blue-400 to-indigo-100 rounded-lg my-10 shadow-xl  md:px-10 px-3">
            {/* <ParticleBackground /> */}
            <Suspense fallback={<div>Loading...</div>}>
                <form className="w-full" action="#">
                    <h2 className="mb-4 text-lg font-medium leading-none text-white dark:text-white">任务细节</h2>
                    <div className=" rounded-lg py-5 grid gap-4 mb-4 sm:grid-cols-1">
                        <div className="w-full">
                            <label className="block mb-2 text-sm font-medium ">发布标题</label>
                            <input value={title} onChange={(e) => setTitle(e.target.value)} type="text" className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="输入你的发布标题" required="" />
                        </div>
                        <div>
                            <label className="block mb-2 text-sm font-medium ">任务分类</label>
                            <TaskCategory value={taskCategory} onChange={setTaskCategory} />
                        </div>
                        <div>
                            <label className="block mb-2 text-sm font-medium">学科分类</label>
                            <SubjectCategory value={subjectCategory} onChange={setSubjectCategory} />
                        </div>
                        <div>
                            <label className="block mb-2 text-sm font-medium">任务简介</label>
                            <textarea
                                className="bg-white  text-gray-700 text-sm rounded-lg focus:ring-0 border-none block w-full p-2.5 "
                                placeholder="任务具体描述"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                rows="10" // Adjust the number of rows as needed
                            ></textarea>
                        </div>
                        <div>
                            <label className="block mb-2 text-sm font-medium">所需技能</label>
                            <TagsInput
                                value={tags}
                                onChange={handleChangeTags}
                                inputValue={inputTag}
                                onChangeInput={handleChangeTagInput}
                                onlyUnique={true}
                                addOnPaste={true}
                                validate={validateInputTag}
                                maxTags={30}
                                addKeys={[9, 13, 188]}
                                className="bg-white border border-gray-300 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 "
                                tagProps={{
                                    className: "react-tagsinput-tag bg-slate-400 text-slate-800 ml-2 mb-2 rounded-lg p-1",
                                    classNameRemove: "react-tagsinput-remove bg-slate-400 text-white rounded-lg ml-1",
                                }}
                                inputProps={{
                                    className: "react-tagsinput-input ${tags && tags.length === 0 ? w-full mt-2 : ''}",
                                    placeholder: `${tags && tags.length > 0 ? '请输入' : '标签最多12个字'}`
                                }}
                                renderTag={customRenderTag}
                            />
                        </div>
                        <div>
                            <label className="block mb-2 text-sm font-medium">截止日期</label>
                            <DatePicker selected={deadline} onChange={(date) => setDeadline(date)} className='bg-white border border-gray-300 text-black text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' />
                            </div>
                    </div>
                    <h2 className="mt-8 text-lg font-medium leading-none">文件上传</h2>
                    <div className="mt-3">
                        <Upload selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} />
                    </div>
                </form>
                <p className='text-red-500 text-sm h-5'>{error}</p>
                <div className=" py-5">
                    <button onClick={handleSubmit} className=" text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        发布任务
                    </button>
                </div>
            </Suspense>

        </div>
    );
};

