import React, { useState } from 'react';
import { HelpCircle, Search, ChevronDown, ChevronUp, Mail, Phone, MessageCircle } from 'lucide-react';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200 py-4">
      <button
        className="flex justify-between items-center w-full text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-sm font-medium text-gray-900">{question}</span>
        {isOpen ? <ChevronUp className="h-5 w-5 text-gray-500" /> : <ChevronDown className="h-5 w-5 text-gray-500" />}
      </button>
      {isOpen && <p className="mt-2 text-sm text-gray-500">{answer}</p>}
    </div>
  );
};

const Help = () => {
  const [searchQuery, setSearchQuery] = useState('');

  const faqs = [
    { question: "如何重置密码？", answer: "要重置密码，请点击登录页面上的\"忘记密码\"链接，然后按照电子邮件中的说明进行操作。" },
    { question: "如何更新我的个人信息？", answer: "登录后，进入\"设置\"页面，然后点击\"个人信息\"部分进行编辑。" },
    { question: "如何取消订阅电子报？", answer: "在\"设置\"中的\"偏好设置\"部分，您可以关闭\"订阅电子报\"开关来取消订阅。" },
    { question: "如何查看我的账单历史？", answer: "在\"设置\"页面的\"支付\"部分，点击\"账单历史\"来查看您的所有过去交易。" },
  ];

  return (
    <div className="bg-white shadow rounded-lg  mx-auto p-6">
      <h2 className="text-2xl font-bold text-gray-900 mb-6 flex items-center">
        <HelpCircle className="mr-2" /> 帮助中心
      </h2>

      <div className="mb-6">
        <div className="relative">
          <input
            type="text"
            placeholder="搜索帮助主题..."
            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
        </div>
      </div>

      <div className="mb-8">
        <h3 className="text-lg font-medium text-gray-900 mb-4">常见问题</h3>
        {faqs.map((faq, index) => (
          <FAQItem key={index} question={faq.question} answer={faq.answer} />
        ))}
      </div>

      <div className="mb-8">
        <h3 className="text-lg font-medium text-gray-900 mb-4">联系我们</h3>
        <div className="space-y-4">
          <div className="flex items-center">
            <Mail className="h-5 w-5 text-gray-400 mr-2" />
            <span className="text-sm text-gray-600">support@example.com</span>
          </div>
          <div className="flex items-center">
            <Phone className="h-5 w-5 text-gray-400 mr-2" />
            <span className="text-sm text-gray-600">400-123-4567</span>
          </div>
          <div className="flex items-center">
            <MessageCircle className="h-5 w-5 text-gray-400 mr-2" />
            <span className="text-sm text-gray-600">在线客服（工作日 9:00-18:00）</span>
          </div>
        </div>
      </div>

      <div>
        <h3 className="text-lg font-medium text-gray-900 mb-4">快速链接</h3>
        <div className="grid grid-cols-2 gap-4">
          <a href="#" className="text-sm text-red-600 hover:text-red-800">使用指南</a>
          <a href="#" className="text-sm text-red-600 hover:text-red-800">隐私政策</a>
          <a href="#" className="text-sm text-red-600 hover:text-red-800">服务条款</a>
          <a href="#" className="text-sm text-red-600 hover:text-red-800">常见问题完整列表</a>
        </div>
      </div>
    </div>
  );
};

export default Help;