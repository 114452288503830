export const SET_USER = 'SET_USER';
export const CLEAR_USER = 'CLEAR_USER';
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE'
export const SET_SUCCESS_MESSAGE = 'SET_SUCCESS_MESSAGE'
export const CLEAR_MESSAGE= 'CLEAR_MESSAGE' // This is used to close the system dialog

export const LOGIN_USER = 'LOGIN_USER';

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

export const RECEIVE_CHAT_MESSAGE = 'RECEIVE_CHAT_MESSAGE'
export const SEND_CHAT_MESSAGE = 'SEND_CHAT_MESSAGE'

export const SET_DIRECT_MESSAGES = 'SET_DIRECT_MESSAGES'
export const SELECT_CONVERSATION = 'SELECT_CONVERSATION'
export const REMOVE_SELECT_CONVERSATION = 'REMOVE_SELECT_CONVERSATION'
export const READ_CHAT_MESSAGES = 'READ_CHAT_MESSAGES'
export const ADD_CONVERSATION = 'ADD_CONVERSATOION'
