import React, { useState } from 'react';

const FilterSection = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [filters, setFilters] = useState({
        categories: [],
        dateRange: 'week',
        popularity: 50
    });

    const handleCategoryChange = (category) => {
        setFilters(prev => ({
            ...prev,
            categories: prev.categories.includes(category)
                ? prev.categories.filter(c => c !== category)
                : [...prev.categories, category]
        }));
    };

    const handleDateRangeChange = (range) => {
        setFilters(prev => ({ ...prev, dateRange: range }));
    };

    const handlePopularityChange = (event) => {
        setFilters(prev => ({ ...prev, popularity: event.target.value }));
    };

    const applyFilters = () => {
        console.log('Applying filters:', filters);
        setIsOpen(false);
    };

    return (
        <div className='flex flex-col relative'>
            <div>
                <button className='' onClick={() => setIsOpen(!isOpen)} style={{ padding: '8px 16px', border: '1px solid #ccc', borderRadius: '4px', background: 'white' }}>
                    Filter
                </button>
            </div>
            {isOpen && (
                <div style={{
                    position: 'absolute',
                    left: 0,
                    top: '100%',
                    width: '250px',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    padding: '16px',
                    background: 'white',
                    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                    zIndex: 10
                }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
                        <h3 style={{ margin: 0 }}>Filters</h3>
                        <button onClick={() => setIsOpen(false)} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>✕</button>
                    </div>

                    <div style={{ marginBottom: '16px' }}>
                        <h4 style={{ marginBottom: '8px' }}>Categories</h4>
                        {['Technology', 'Science', 'Arts', 'Sports'].map((category) => (
                            <div key={category} style={{ marginBottom: '4px' }}>
                                <label style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                        type="checkbox"
                                        checked={filters.categories.includes(category)}
                                        onChange={() => handleCategoryChange(category)}
                                        style={{ marginRight: '8px' }}
                                    />
                                    {category}
                                </label>
                            </div>
                        ))}
                    </div>

                    <div style={{ marginBottom: '16px' }}>
                        <h4 style={{ marginBottom: '8px' }}>Date Range</h4>
                        {['week', 'month', 'year'].map((range) => (
                            <div key={range} style={{ marginBottom: '4px' }}>
                                <label style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                        type="radio"
                                        value={range}
                                        checked={filters.dateRange === range}
                                        onChange={() => handleDateRangeChange(range)}
                                        style={{ marginRight: '8px' }}
                                    />
                                    Past {range}
                                </label>
                            </div>
                        ))}
                    </div>

                    <div style={{ marginBottom: '16px' }}>
                        <h4 style={{ marginBottom: '8px' }}>Popularity (Likes)</h4>
                        <input
                            type="range"
                            min="0"
                            max="100"
                            value={filters.popularity}
                            onChange={handlePopularityChange}
                            style={{ width: '100%' }}
                        />
                        <div style={{ textAlign: 'center' }}>{filters.popularity}</div>
                    </div>

                    <button onClick={applyFilters} style={{
                        width: '100%',
                        padding: '8px',
                        background: '#007bff',
                        color: 'white',
                        border: 'none',
                        borderRadius: '4px',
                        cursor: 'pointer'
                    }}>
                        Apply Filters
                    </button>
                </div>
            )}
        </div>
    );
};

export default FilterSection;