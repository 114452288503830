// src/reducers/someReducer.js
import { SET_USER, CLEAR_USER } from '../actions/actionTypes';


const initialState = {
    token: null,
    email: null,
    userId: null,
    // Any other initial state properties you have
  };

export default function userReducer(state = initialState, action) {
    switch (action.type) {
        case SET_USER:
            return { ...state, ...action.payload };
        case CLEAR_USER:
            return {
                ...initialState
            };
        default:
            return state;
    }
}


