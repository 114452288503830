import { motion, AnimatePresence } from "framer-motion"
import { Bell, X, Dot } from "lucide-react"
import { useState, useEffect, useRef } from "react";
import axios from 'axios'
import { format } from 'date-fns';

let BACKEND_URL = import.meta.env.VITE_BACKEND_URL
const POLL_INTERVAL = 10000;
export default function NotificationDropdown() {
    const [notifications, setNotifications] = useState([{ id: 1, message: "New message from John Doe", time: "2 minutes ago" },
    { id: 2, message: "You have a new follower", time: "1 hour ago" },
    { id: 3, message: "Your post has 100 likes", time: "3 hours ago" },
    { id: 4, message: "Meeting reminder: Team sync at 2 PM", time: "5 hours ago" },
    { id: 5, message: "New comment on your article", time: "1 day ago" },
    ])
    const [isOpen, setIsOpen] = useState(false);
    const [dropdownStyle, setDropdownStyle] = useState({});
    const containerRef = useRef(null);
    const dropdownRef = useRef(null);
    // console.log({ notifications })
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (isOpen && containerRef.current && dropdownRef.current) {
            const updatePosition = () => {
                const containerRect = containerRef.current.getBoundingClientRect();
                const dropdownRect = dropdownRef.current.getBoundingClientRect();
                const screenWidth = document.documentElement.clientWidth;
                console.log('drop down rect', dropdownRect)
                console.log('container rect', containerRect)

                // Start with right alignment
                let rightPosition = 0;
                let rightValue = (dropdownRect.width - containerRect.width) / 2
                if (containerRect.right + rightValue > screenWidth - 10) {
                    rightPosition = -(screenWidth - containerRect.right - 10)
                } else {
                    rightPosition = -rightValue
                }
                console.log('rightPosition', rightPosition)
                setDropdownStyle({ right: `${rightPosition}px` });
            };

            updatePosition();
            window.addEventListener('resize', updatePosition);

            return () => {
                window.removeEventListener('resize', updatePosition);
            };
        }
    }, [isOpen]);

    useEffect(() => {
        let timeoutId
        async function getNotifications() {
            let config = {
                params: {
                    recipientId: '668f9dbcda1ec92b95b52091',
                    read: false
                }
            }
            let res = await axios.get(BACKEND_URL + '/api/notifications', config)
            setNotifications(res.data)
            timeoutId = setTimeout(() => {
                getNotifications()
            }, POLL_INTERVAL);
        }

        getNotifications()
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        }
    }, [])


    return (
        <div ref={containerRef} className="relative text-gray-700 text-sm">
            <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className="p-1 rounded-full text-white"
                onClick={() => setIsOpen(!isOpen)}
            >
                <Bell size={26} />
            </motion.button>
            <span className="absolute top-0 right-0 block h-4 w-4 rounded-full bg-red-500 border-2 border-white text-xs text-white flex items-center justify-center">
                {notifications.length}
            </span>
            <AnimatePresence>
                {isOpen &&
                    <>
                        <motion.div
                            ref={dropdownRef}
                            style={dropdownStyle}
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -10 }}
                            className="absolute mt-2 w-80 rounded-md shadow-lg py-1 bg-white">
                            <div className="px-4 py-3 border-b border-gray-200 flex justify-between items-center">
                                <h3 className="text-lg font-semibold text-gray-900">Notifications</h3>
                                <button
                                    onClick={() => setIsOpen(false)}
                                    className="text-gray-400 hover:text-gray-500"
                                >
                                    <X size={18} />
                                </button>
                            </div>
                            <div className="max-h-96 overflow-y-auto">
                                {notifications.map((notification) => (
                                    <div
                                        key={notification._id}
                                        className="flex justify-between px-4 py-3 hover:bg-gray-50 transition-colors duration-150 ease-in-out"
                                    >
                                        <div>
                                            
                                        <p className="text-sm text-gray-800">{notification.content}</p>

                                        <p className="text-xs text-gray-500 mt-1">{notification.createdAt ? 'time: ' + format(new Date(notification.createdAt), 'yyyy-MM-dd HH:mm:ss') : 'time: "No time specified"'}</p>
                                        </div>
                                        <Dot color="#0de4e7" strokeWidth={5} />
                                    </div>
                                ))}
                            </div>
                            {/* <div className="px-4 py-3 bg-gray-50 text-center rounded-b-lg">
                                <button className="text-sm text-blue-600 hover:text-blue-800 font-medium">
                                    View all notifications
                                </button>
                            </div> */}
                        </motion.div>

                    </>
                }
            </AnimatePresence>

        </div>
    )
}