import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { removeNotification } from '@/redux/actions/notificationAction';

const Notification = ({ id, message }) => {
    const dispatch = useDispatch();
  
    useEffect(() => {
      const timer = setTimeout(() => {
        dispatch(removeNotification(id));
      }, 5000); // 1000ms = 1 second
  
      return () => clearTimeout(timer);
    }, [id, dispatch]);
  
    return (
      <div className="bg-green-100 text-green-600 py-2 px-4 rounded shadow-xl mb-2">
        {message}
      </div>
    );
  };


const NotificationContainer = () => {
  const notifications = useSelector((state) => state.notifications);
    console.log('NotificationContainer:', notifications)
  return (
    <div className="fixed z-50 top-5 left-1/2 -translate-x-1/2 flex flex-col items-center">
    {notifications.map((notification) => (
      <Notification key={notification.id} id={notification.id} message={notification.message} />
    ))}
  </div>
  );
};

export default NotificationContainer;
