import { useState, useEffect } from "react";
import { Star } from "lucide-react";
import axios from "axios";
export default function Reviews({ userId }) {
    const [reviews, setReviews] = useState([
        { id: 1, author: '张三', content: '很棒的合作伙伴！Jane 是一个解决问题的高手和优秀的团队成员。', rating: 5, date: '2023-05-15' },
        { id: 2, author: '李四', content: '知识渊博，乐于助人。总是能够交付高质量的工作。', rating: 4, date: '2023-06-22' },
    ]);

    useEffect(() => {
        async function getReviews() {
            let response = await axios.get(import.meta.env.VITE_BACKEND_URL + '/api/get-reviews', {
                params: {
                    userId
                },
            })
            console.log(response.data)
            setReviews(response.data.reviews)
        }
        getReviews()
    }, [])

    return (
        <div>
            <h2 className="text-xl font-semibold mb-3 text-gray-800">评价</h2>
            <div className="space-y-4 mb-4">
                {reviews.map((review) => (
                    <div key={review.id} className="bg-gray-50 p-4 rounded-lg">
                        <div className="flex items-center mb-2">
                            {[...Array(review.rating)].map((_, i) => (
                                <Star key={i} className="text-yellow-400" size={16} />
                            ))}
                            <span className="text-sm text-gray-500 ml-2">{review.date}</span>
                        </div>
                        <p className="text-sm text-gray-700 mb-1">{review.content}</p>
                        <p className="text-xs text-gray-500">- {review.author}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}