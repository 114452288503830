import { useSelector } from 'react-redux';
import { useState } from 'react';
import { format } from 'date-fns';
import axios from 'axios';
import { MessageCircleReply, ArrowBigRight } from 'lucide-react'
const BASE_BACKEND_URL = import.meta.env.VITE_BACKEND_URL


export default function SubComment({ comment, postId, refreshComments}) {
    console.log('Sub Comment:', comment._id , comment)
    const [showReplyForm, setShowReplyForm] = useState(false);
    const [replyBody, setReplyBody] = useState('');
    const [error, setError] = useState('')
    const user = useSelector((state) => state.user)
    console.log('user: comment id: ', comment._id, user)

    const handleReplySubmit = async () => {
        if (!replyBody) {
            setError('请输入内容');
            return;
        }
        if (!postId) {
            console.log('no post id')
            return
        }
        try {
            const response = await axios.post(`${BASE_BACKEND_URL}/api/create-comment`, {
                postId,
                userId: user.userId,
                parentCommentId: comment.parentCommentId,
                replyToCommentId: comment._id,
                username: user.username ? user.username : null,
                replyToUsername: comment?.username,
                body: replyBody
            });

            setReplyBody('');
            setShowReplyForm(false);
            setError('');
            refreshComments()
        } catch (error) {
            console.error('Error submitting reply:', error);
        }
    };
    return (
        <div className='relative p-2'>
            <div className='flex'>
                <div className='mr-2'>
                    <img className='h-6 w-6 rounded-full' src={comment?.senderUser?.profile?.profilePicture || '/defaultAvatar.jpg'} />
                </div>
                <div>
                    <div className="text-sm text-gray-600 mb-2 flex items-top">
                        <p className=''>
                            {comment?.senderUser?.username || comment?.userId || 'Unknown user'}
                        </p>
                        <ArrowBigRight className='w-5 h-5' strokeWidth={1.75} />
                        <p className=''>
                            {comment?.recipientUser.username} :
                        </p>
                    </div>
                    <p className="text-gray-800 text-sm">{comment.body} <span className='ml-3 text-sm text-gray-600'>{comment?.createdAt && format(new Date(comment?.createdAt), 'yyyy-MM-dd HH:mm:ss')}</span></p>

                </div>
            </div>
            {showReplyForm ? (
                <div className='mt-2'>
                    <textarea
                        className="w-full p-2 border rounded-lg resize-none"
                        rows="2"
                        placeholder="添加回复..."
                        value={replyBody}
                        onChange={(e) => setReplyBody(e.target.value)}
                    ></textarea>
                    <p className='text-red-500 text-sm'>{error}</p>
                    <button onClick={handleReplySubmit} className="mt-2 px-3 py-1 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-300">
                        回复
                    </button>
                    <button type="button" className="mt-2 ml-2 px-3 py-1 bg-gray-300 text-gray-800 rounded-lg hover:bg-gray-400 transition duration-300" onClick={() => setShowReplyForm(false)}>
                        取消
                    </button>
                </div>
            ) : (
                <button className='absolute bottom-1 right-1 text-sm text-blue-400 hover:text-blue-500' onClick={() => setShowReplyForm(true)}>
                    <MessageCircleReply strokeWidth={1.75} />
                </button>
            )}
        </div>
    )
}