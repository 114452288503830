import React from 'react';
import { Calendar, Star, Clock } from 'lucide-react';

const TaskCard = ({ task, onClick }) => (
    <div 
        onClick={onClick}
        className="bg-white shadow-lg rounded-lg overflow-hidden mb-10 px-6 py-2 cursor-pointer"
    >
        <h3 className="font-medium text-lg mb-2 text-gray-800">{task.title}</h3>
        <p className="text-gray-600 text-sm mb-4">{task.description}</p>
        <div className="flex justify-between items-center text-xs text-gray-500">
            <div className="flex items-center">
                <Calendar size={16} className="mr-1" />
                <span>{new Date(task.deadline).toLocaleDateString()}</span>
            </div>
            <div className="flex items-center">
                <Star size={16} className="mr-1 text-yellow-500" />
                <span>{task.total_score} 分</span>
            </div>
            <div className="flex items-center">
                <Clock size={16} className="mr-1 text-blue-500" />
                <span>预期: {task.expected_score} 分</span>
            </div>
        </div>
    </div>
);

export default TaskCard;