import { motion, AnimatePresence } from "framer-motion"
import { ChevronDown, User, Settings, LogOut, MessageCircle } from "lucide-react"
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { clearUser } from '@/redux/actions/userAction';

export default function ProfileDropdown({ user }) {
    let dispatch = useDispatch()
    const [profileOpen, setProfileOpen] = useState(false);
    const containerRef = useRef(null);
    const dropdownRef = useRef(null);

    function close() {
        setProfileOpen(false)
    }

    function logout() {
        dispatch(clearUser())
        close()
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                close()
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div ref={containerRef} className="relative">
            <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setProfileOpen(!profileOpen)}
                className="flex items-center space-x-2 text-sm"
            >
                <img className="h-8 w-8 rounded-full" src={user?.profile?.profilePicture || "/defaultAvatar.jpg"} alt="" />
                <span className="hidden lg:inline">{user.email}</span>
                <ChevronDown size={16} />
            </motion.button>

            <AnimatePresence>
                {profileOpen && (
                    <motion.div
                        ref={dropdownRef}
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        className="absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white"
                    >

                        <Link onClick={close} to="/app/user-profile" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                            <User className="inline-block mr-2" size={16} />
                            个人资料
                        </Link>
                        <Link onClick={close} to="/app/messages" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                            <MessageCircle className="inline-block mr-2" size={16} />
                            消息
                        </Link>
                        <Link onClick={close} to="/app/user-profile/settings" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                            <Settings className="inline-block mr-2" size={16} />
                            设置
                        </Link>
                        <button onClick={logout} className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                            <LogOut className="inline-block mr-2" size={16} />
                            退出登录
                        </button>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    )
}