import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ChevronLeft, ChevronRight, AlertCircle } from 'lucide-react';
import TaskCard from './PostCard'; // Adjust the import path as necessary
import FilterSection from './FilterSection';

let BASE_BACKEND_URL = import.meta.env.VITE_BACKEND_URL

export default function TaskList() {
    const navigate = useNavigate();
    const [tasks, setTasks] = useState([]);
    // const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalTasks, setTotalTasks] = useState(0);
    const [pageInput, setPageInput] = useState('');
    const limit = 10;

    const totalPages = Math.ceil(totalTasks / limit);

    useEffect(() => {
        const fetchTasks = async () => {
            setError('');
            try {
                const response = await axios.get(`${BASE_BACKEND_URL}/api/get-post-list?page=${currentPage}&limit=${limit}`);
                // console.log(response)
                setTasks(response.data.posts);
                setTotalTasks(response.data.pagination.total);
            } catch (error) {
                console.error('Failed to fetch tasks:', error);
                setError('Failed to fetch tasks. Please try again later.');
            }
        };

        fetchTasks();
    }, [currentPage]);

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const handlePageInput = (e) => {
        setPageInput(e.target.value);
    };

    const goToPage = (e) => {
        e.preventDefault();
        const pageNum = parseInt(pageInput, 10);
        if (pageNum >= 1 && pageNum <= totalPages) {
            setCurrentPage(pageNum);
            setPageInput('');
        }
    };

    return (
        <div className="min-h-screen container mx-auto px-4 py-8 relative z-10">
            <h1 className="text-xl font-medium mb-8 text-center text-white">任务列表</h1>
            <FilterSection />

            {error ? (
                <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert">
                    <div className="flex">
                        <AlertCircle className="flex-shrink-0 mr-3" />
                        <p>{error}</p>
                    </div>
                </div>
            ) : (
                <>
                    {tasks.map((task) => (
                        <TaskCard
                            key={task._id}
                            task={task}
                            onClick={() => navigate(`/app/task-detail/${task.id}`)}
                        />
                    ))}

                    <div className="mt-8 flex justify-center items-center space-x-1 lg:space-x-4">
                        <button
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                            className="lg:px-4 py-2 bg-blue-500 text-white rounded-md disabled:bg-gray-300 disabled:cursor-not-allowed"
                        >
                            <ChevronLeft size={20} />
                        </button>
                        <span className="text-white">第 {currentPage} 页，共 {totalPages} 页</span>
                        <button
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage >= totalPages}
                            className="lg:px-4 py-2 bg-blue-500 text-white rounded-md disabled:bg-gray-300 disabled:cursor-not-allowed"
                        >
                            <ChevronRight size={20} />
                        </button>
                        <div className="flex items-center">
                            <input
                                type="number"
                                value={pageInput}
                                onChange={handlePageInput}
                                placeholder="跳转到..."
                                className="w-16 lg:w-20 px-2 py-1 border rounded-md mr-2"
                            />
                            <button className="px-1 lg:px-4 py-1 lg:py-2 bg-blue-500/80 text-white rounded-md">
                                跳转
                            </button>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}