import React from 'react';
import { FileText } from 'lucide-react';

function TaskContent({ taskDetail }) {
    return (
        <div className="mx-auto font-sans text-gray-800">
            <section className="mb-8">
                <h2 className="text-base font-semibold mb-3 pb-1 border-b border-gray-200">任务内容</h2>
                <p className="text-sm leading-relaxed">{taskDetail.description}</p>
            </section>

            <section className="mb-8">
                <h2 className="text-base font-semibold mb-3 pb-1 border-b border-gray-200">所需技能</h2>
                <div className="flex flex-wrap gap-2">
                    {taskDetail.taskSkills?.map((skill, index) => (
                        <span key={index} className="text-xs px-2 py-1 bg-gray-100 rounded">
                            {skill}
                        </span>
                    ))}
                </div>
            </section>

            <section>
                <h2 className="text-base font-semibold mb-3 pb-1 border-b border-gray-200">文件</h2>
                <ul className="space-y-2">
                    {taskDetail.files?.map((file, index) => (
                        <li key={index}>
                            <a
                                href={file.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex items-center text-sm text-blue-600 hover:underline"
                            >
                                <FileText className="h-4 w-4 mr-2" />
                                {file.name}
                            </a>
                        </li>
                    ))}
                </ul>
            </section>
        </div>
    );
}

export default TaskContent;