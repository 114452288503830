
import { useSelector } from "react-redux";
const ProfileDetail = () => {
    let user = useSelector(state => state.user)
    return (
        <div className="w-full">
            <div className="bg-white p-4 border border-gray-200 rounded-lg mb-4">
                <h2 className="text-lg font-semibold mb-2">基本信息</h2>
                <div className="flex justify-between items-center pr-4 pl-4 pt-1 pb-1">
                    <div className="text-gray-600 text-sm">头像</div>
                    <div className="ml-12">
                        <div className="w-12 h-12 overflow-hidden bg-gray-200 rounded-full flex items-center justify-center text-sm font-semibold">
                            <img className="w-full h-full" src="/defaultAvatar.jpg" alt="" />
                            </div>
                    </div>
                    <div className="ml-auto text-sm">
                        <button className="text-blue-700 text-gray-900">修改头像</button>
                    </div>
                </div>

                <div className="flex justify-between items-center pr-4 pl-4 pt-1 pb-1">
                    <div className="text-gray-600 text-sm">昵称</div>
                    <div className="ml-12">
                        <div className="font-bold text-gray-900 text-sm">{user?.username}</div>
                    </div>
                    <div className="ml-auto text-sm">
                        <button className="text-blue-700 text-gray-900">修改昵称</button>
                    </div>
                </div>

                <div className="flex justify-between items-center pr-4 pl-4 pt-1 pb-1">
                    <div className="text-gray-600 text-sm">Email</div>
                    <div className="ml-11">
                        <div className="font-bold text-gray-900 text-sm">{user?.email}</div>
                    </div>
                    <div className="ml-auto text-sm">
                        <button className="text-blue-700 text-gray-900">绑定邮箱</button>
                    </div>
                </div>
            </div>

            <div className="bg-white p-4 border border-gray-200 rounded-lg mb-4">
                <h2 className="text-lg font-semibold mb-2">银行卡</h2>
                <div className="flex justify-between items-center">
                    <div className="text-gray-900 text-sm">***** **** **1250</div>
                    <div>
                        <button className="text-blue-700 text-gray-900 mr-2 text-sm">编辑</button>
                        <button className="text-blue-700 text-gray-900 mr-2 text-sm">删除</button>
                    </div>
                </div>
                <div className="flex justify-center">
                    <button className="bg-gray-200 text-gray-700 px-4 py-3 rounded-lg hover:bg-gray-300 text-sm">
                        点击绑定银行卡
                    </button>
                </div>
            </div>
            
            <div className="bg-white p-4 border border-gray-200 rounded-lg">
                <h2 className="text-lg font-semibold">偏好设置</h2>
            </div>
        </div>

    );
};

export default ProfileDetail;


