import { useState } from 'react';
import { MessageSquare, UserCircle, Phone, Calendar, ThumbsUp, ThumbsDown, Flag } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
export default function OfferLine({ offer }) {
    let navigate = useNavigate()
    const [open, setOpen] = useState(false)
    return (
        <tr>
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{offer.name}</td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">${offer.price}</td>
            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium relative">
                <button onClick={() => setOpen(!open)} className="text-blue-600 hover:text-blue-900">
                    <MessageSquare className="h-5 w-5" />
                </button>
                {open && (
                    <div className="absolute right-0 bottom-full z-10 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                        <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                            <button onClick={() => navigate('/app/public-user-profile')} className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                                <UserCircle className="inline-block mr-2 h-4 w-4" /> 查看档案
                            </button>
                            <button className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                                <Phone className="inline-block mr-2 h-4 w-4" /> 联系报价人
                            </button>
                            <button className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                                <Calendar className="inline-block mr-2 h-4 w-4" /> 安排面谈
                            </button>
                            <button className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                                <ThumbsUp className="inline-block mr-2 h-4 w-4" /> 添加到候选名单
                            </button>
                            <button className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                                <ThumbsDown className="inline-block mr-2 h-4 w-4" /> 拒绝此报价
                            </button>
                            <button className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                                <Flag className="inline-block mr-2 h-4 w-4" /> 举报不当行为
                            </button>
                            <hr className="my-1" />
                            <button className="block w-full text-left px-4 py-2 text-sm text-green-600 hover:bg-green-100 hover:text-green-700" role="menuitem">
                                选择此报价
                            </button>
                        </div>
                    </div>
                )}
            </td>
        </tr>
    )
}