import { useState, useEffect } from 'react';

interface Props {
    file: File
}

const FilePreview = ({ file }: Props) => {
    const [previewUrl, setPreviewUrl] = useState<any>(null);
    // console.log('FilePreview', { file, previewUrl })

    const fileToDataUrl = (file: File) => {
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(file);
        });
      };

    useEffect(() => {
        let isMounted = true;
        async function processFile() {
            if (file) {
                const url =  await fileToDataUrl(file);
                // Cleanup function to revoke the URL when component unmounts or file changes
                if (isMounted) {
                    setPreviewUrl(url);       
                }
            }
        }
        processFile()
        return () => {
            isMounted = false;
            if (previewUrl) {
                URL.revokeObjectURL(previewUrl);
            }

        }
    }, [file]); // Re-run effect if file changes

    if (!file) return null;

    return (
        <div className='flex flex-col items-start bg-white'>
            {file && file.type.startsWith('image/') ? (
                <img src={previewUrl || ''} alt="File preview" className={'cursor-pointer bg-white object-contain w-[100px] h-[100px]'} />
            ) : (
                <div className='md:flex md:space-x-3 text-blue-500 border px-2 py-1 shadow-lg bg-white'>
                    <p>{file.name}</p>
                    <p>{file.type}</p>
                    <p>{Math.round(file.size / 1024)} KB</p>
                </div>
            )}
        </div>
    );
};

export default FilePreview;