import React, { useState } from 'react';
import { Bell, Lock, User, Shield, CreditCard, HelpCircle, Mail, Smartphone, Activity, Settings as SettingsIcon } from 'lucide-react';

const SettingItem = ({ icon: Icon, title, description, action }) => (
  <div className="flex items-center justify-between p-4 hover:bg-gray-50 transition-colors duration-150 ease-in-out border-b border-gray-200 last:border-b-0">
    <div className="flex items-center space-x-3">
      <div className="p-2 bg-red-100 rounded-full">
        <Icon size={20} className="text-red-600" />
      </div>
      <div>
        <h3 className="text-sm font-medium text-gray-900">{title}</h3>
        <p className="text-xs text-gray-500">{description}</p>
      </div>
    </div>
    {action}
  </div>
);

const Switch = ({ enabled, setEnabled }) => (
  <button
    onClick={() => setEnabled(!enabled)}
    className={`${
      enabled ? 'bg-red-600' : 'bg-gray-200'
    } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2`}
  >
    <span
      className={`${
        enabled ? 'translate-x-5' : 'translate-x-0'
      } pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
    />
  </button>
);

const Settings = () => {
  const [notifications, setNotifications] = useState(true);
  const [twoFactor, setTwoFactor] = useState(false);
  const [newsletter, setNewsletter] = useState(true);
  const [activityLog, setActivityLog] = useState(false);

  return (
    <div className="bg-white shadow rounded-lg w-full mx-auto">
      <div className="px-4 py-5 sm:p-6">
        <h2 className="text-lg font-medium leading-6 text-gray-900 mb-4 flex items-center">
          <SettingsIcon className="mr-2" /> 用户设置
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="space-y-1">
            <h3 className="font-medium text-gray-900">账户</h3>
            <SettingItem
              icon={User}
              title="个人信息"
              description="更新您的账户详细信息和头像"
              action={<span className="text-red-600 text-sm cursor-pointer">编辑</span>}
            />
            <SettingItem
              icon={Lock}
              title="密码"
              description="更改密码或设置密码恢复选项"
              action={<span className="text-red-600 text-sm cursor-pointer">修改</span>}
            />
            <SettingItem
              icon={Shield}
              title="两步验证"
              description="为您的账户添加额外的安全层"
              action={<Switch enabled={twoFactor} setEnabled={setTwoFactor} />}
            />
          </div>
          <div className="space-y-1">
            <h3 className="font-medium text-gray-900">偏好设置</h3>
            <SettingItem
              icon={Bell}
              title="通知"
              description="管理您的邮件和推送通知"
              action={<Switch enabled={notifications} setEnabled={setNotifications} />}
            />
            <SettingItem
              icon={Mail}
              title="订阅电子报"
              description="接收我们的每周通讯和产品更新"
              action={<Switch enabled={newsletter} setEnabled={setNewsletter} />}
            />
            <SettingItem
              icon={Activity}
              title="活动日志"
              description="跟踪和查看您的账户活动"
              action={<Switch enabled={activityLog} setEnabled={setActivityLog} />}
            />
          </div>
          <div className="space-y-1">
            <h3 className="font-medium text-gray-900">隐私和安全</h3>
            <SettingItem
              icon={Smartphone}
              title="已登录设备"
              description="查看已登录您账户的设备"
              action={<span className="text-red-600 text-sm cursor-pointer">查看</span>}
            />
            <SettingItem
              icon={Lock}
              title="登录历史"
              description="查看您最近的登录活动"
              action={<span className="text-red-600 text-sm cursor-pointer">查看</span>}
            />
          </div>
          <div className="space-y-1">
            <h3 className="font-medium text-gray-900">支付</h3>
            <SettingItem
              icon={CreditCard}
              title="支付方式"
              description="管理您的支付选项和账单详情"
              action={<span className="text-red-600 text-sm cursor-pointer">管理</span>}
            />
            <SettingItem
              icon={HelpCircle}
              title="帮助与支持"
              description="获取账户帮助或联系客服"
              action={<span className="text-red-600 text-sm cursor-pointer">联系</span>}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;