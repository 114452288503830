import { format } from 'date-fns'
import RenderFile from './RenderFile';

interface Message {
    id: string;
    sender: {
        id: string
    };
    recipient: {
        id: string
    };
    content?: string;
    upload: {
        id: string;
        url: string;
        type: string
    }
    type: string;
    status: string;
    createdAt: string
}


export default function SingleMessage({ message, senderId, recipientId }: {
    message: Message,
    senderId: string, recipientId: String
}) {
    // console.log('Single Message', { message, senderId, recipientId })
    if (!message) {
        return null
    }
    return (
        <div className={`flex ${message.sender.id === senderId || message.sender.id !== recipientId ? 'justify-end' : 'justify-start'}`}>
            {message.upload?.id ?
                <div className='w-[200px] h-[200px]'>
                    <RenderFile upload={message.upload} />
                </div>
                :
                <div className={`max-w-xs lg:max-w-lg px-4 py-2 rounded-lg ${message.sender.id === senderId || message.sender.id !== recipientId
                    ? 'bg-gradient-to-r from-indigo-500 to-purple-600 text-white'
                    : 'bg-white text-gray-800 shadow-lg'
                    }`}>
                    <p className="text-sm">{message.content}</p>
                    <span className={`text-xs ${message.status ? 'text-indigo-200' : 'text-gray-400'} mt-1 block`}>
                        {format(new Date(message.createdAt), 'yyyy-MM-dd HH:mm:ss')}
                    </span>
                </div>
            }
        </div>
    )
}