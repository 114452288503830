export default function Upload({selectedFiles, setSelectedFiles}) {
    const handleFileChange = (event) => {
        const files = event.target.files;
        console.log(files);
        if (files) {
            let newFiles = [...selectedFiles, ...files]
            console.log(newFiles)
            setSelectedFiles(newFiles)
        }
        event.target.value = '';
    };
    const deleteFile = (index) => {
        setSelectedFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    };
    return (
        <div className="w-[130px] mb-2">
            <div >
                <label htmlFor="dropzone-file" className="flex w-full h-8 flex-col items-center justify-center border-2 border-gray-300 rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-300">
                    <div className="bg-slate-100 flex flex-col items-center justify-center">
                        <svg className="w-8 h-5 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                        </svg>
                    </div>
                    <input id="dropzone-file" onChange={handleFileChange} multiple type="file" className="hidden" />
                </label>
            </div>
            <div>
                {selectedFiles.length > 0 && (
                    <ul>
                        {selectedFiles.map((file, index) => (
                            <li className="pt-2" key={index}>
                                <div className="place-items-center flex flex-row text-base ">
                                    <div className="place-items-center">
                                    <svg className="text-slate-500 flex-none w-5 h-5" onClick={() => deleteFile(index)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                    </svg>
                                    </div>
                                   
                                    <span className="whitespace-nowrap content-center text-slate-600 ml-2 grow">
                                        {file.name}
                                    </span>
                                </div>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    )
}