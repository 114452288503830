import { RECEIVE_CHAT_MESSAGE, SEND_CHAT_MESSAGE, SET_DIRECT_MESSAGES, SELECT_CONVERSATION, REMOVE_SELECT_CONVERSATION, READ_CHAT_MESSAGES, ADD_CONVERSATION } from "./actionTypes.tsx";

interface User {
    id: string;
    username: string
}

export const receiveChatMessage = (parsedMessage: any) => ({
    type: RECEIVE_CHAT_MESSAGE,
    payload: parsedMessage
})

export const sendChatMessage = (parsedMessage: any) => ({
    type: SEND_CHAT_MESSAGE,
    payload: parsedMessage
})

export const readChatMessage = (selfId: string, othersId: string) => ({
    type: READ_CHAT_MESSAGES,
    payload: {selfId, othersId}
})

export const setDirectMessages = (directMessages: any) => ({
    type: SET_DIRECT_MESSAGES,
    payload: directMessages
})

export const selectConversation = (id: any) => ({
    type: SELECT_CONVERSATION,
    payload: id
})

export const removeSelectConversation = () => ({
    type: REMOVE_SELECT_CONVERSATION
})

export const addConversation = (user: User) => ({
    type: ADD_CONVERSATION,
    payload: user
})