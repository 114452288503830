import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import TaskHeader from './TaskHeader';
import TaskContent from './TaskContent';
import Comments from './comments/Comments';
import BidSection from './TaskSideSection';
import OfferList from './OfferSection/OfferList';

let BASE_BACKEND_URL = import.meta.env.VITE_BACKEND_URL

function MyTaskDetail() {
    const { postId } = useParams();
    const user = useSelector(state => state.user);
    const [taskDetail, setTaskDetail] = useState(null);
    const [isOwner, setIsOwner] = useState(false);
    console.log('my task detail:' , {taskDetail})
    useEffect(() => {
        async function getData() {
            try {
                let config = { params: { postId } };
                let response = await axios.get(`${BASE_BACKEND_URL}/api/get-post-by-id`, config);
                let post = response.data.post
                setTaskDetail(post);
                if (!post?.userId) {
                    setIsOwner(true)
                } else {
                    setIsOwner(user.userId === post?.userId);
                }
            } catch (error) {
                console.error('Error fetching task details:', error);
            }
        }
        getData();
    }, [postId, user.userId]);

    if (!taskDetail) {
        return <div className="flex justify-center items-center h-screen text-white">任务不存在或正在加载中...</div>;
    }

    return (
        <div className="min-h-screen">
            {/* <ParticleBackground /> */}
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
                <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                    <TaskHeader taskDetail={taskDetail} isOwner={isOwner} />
                    <div className="flex flex-col">
                        <div className="w-full p-6">
                            <TaskContent taskDetail={taskDetail} />
                            <Comments postId={postId} />
                        </div>
                        <div className="w-full p-6 bg-gray-50">
                            {/* {isOwner ? ( */}
                                <OfferList taskDetail={taskDetail} />
                            {/* ) : ( */}
                                {/* <BidSection taskDetail={taskDetail} /> */}
                            {/* )} */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MyTaskDetail;