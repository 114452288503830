import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { User, Mail, Lock } from 'lucide-react';
import axios from 'axios'
import useParticleBackground from '@/hooks/useParticleBackground';

const BACKEND_URL = import.meta.env.VITE_BACKEND_URL
const RegistrationPage = () => {
    const canvasRef = useParticleBackground()

    const navigate = useNavigate();
    const [error, setError] = useState('')
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        password: '',
        confirmPassword: '',
    });

    const [disable, setDisable] = useState(false)

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    async function handleSubmit(e) {
        e.preventDefault()
        if (disable) {
            console.log('handle submit is disabled')
            return
        }
        if (!(formData.fullName && formData.email && formData.password)) {
            setError('*不可为空')
            return
        } else if (formData.password !== formData.confirmPassword) {
            setError('*密码不一致')
            return
        }
        setDisable(true)
        console.log('form data', formData)

        try {
            let data = {
                email: formData.email,
                password: formData.password,
                fullName: formData.fullName
            }
            let response = await axios.post(`${BACKEND_URL}/api/register`, data)
            console.log(response)
            navigate('/login');
        } catch (err) {
            console.error('error', err)
            setError(err.response?.data?.message)
        }

        setDisable(false)
    };

    return (
        <>
            <div ref={canvasRef} className="fixed inset-0 z-0" />

            <div className="min-h-screen bg-gradient-to-b from-blue-900 via-purple-900 to-indigo-900 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">


                <div className="max-w-md w-full space-y-8 bg-white bg-opacity-10 p-10 rounded-xl backdrop-filter backdrop-blur-lg">
                    <div>
                        <h2 className="mt-6 text-center text-3xl font-extrabold text-white">
                            创建你的学习账户
                        </h2>
                        <p className="mt-2 text-center text-sm text-gray-300">
                            加入我们，开启你的学习之旅
                        </p>
                    </div>
                    <form className="mt-8 space-y-6">
                        <div className="rounded-md shadow-sm -space-y-px">
                            <div className="flex items-center mb-4">
                                <User className="h-5 w-5 text-indigo-500 mr-2" />
                                <input
                                    id="fullName"
                                    name="fullName"
                                    type="text"
                                    required
                                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                    placeholder="用户名"
                                    value={formData.fullName}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="flex items-center mb-4">
                                <Mail className="h-5 w-5 text-indigo-500 mr-2" />
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                    placeholder="电子邮箱"
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="flex items-center mb-4">
                                <Lock className="h-5 w-5 text-indigo-500 mr-2" />
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="new-password"
                                    required
                                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                    placeholder="密码"
                                    value={formData.password}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="flex items-center mb-4">
                                <Lock className="h-5 w-5 text-indigo-500 mr-2" />
                                <input
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    type="password"
                                    autoComplete="new-password"
                                    required
                                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                    placeholder="确认密码"
                                    value={formData.confirmPassword}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div>
                            <p className='h-3 text-red-500 text-sm '>
                                {error}
                            </p>
                        </div>
                        <div>
                            <button
                                onClick={handleSubmit}
                                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                注册账户
                            </button>
                        </div>
                    </form>
                    <div className="text-center">
                        <p className="text-sm text-gray-300">
                            已有账户？
                            <Link to={"/login"} className="font-medium text-indigo-400 hover:text-indigo-300 ml-1">
                                立即登录
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RegistrationPage;