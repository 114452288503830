import { createBrowserRouter, RouterProvider, } from "react-router-dom";
import Home from "@/pages/main-page";
import CreateTask from "@/pages/new-post";
import MyTaskDetail from "@/pages/my-task-detail";
import LogInPage from "@/pages/log-in";
import UserProfilePage from "@/pages/user-profile/UserProfilePage";
import BalanceDetail from "./pages/user-profile/AccountBalance";
import ProfileDetail from "./pages/user-profile/Profile";
import Withdraw from "@/pages/user-profile/Withdraw";
import Message from "@/pages/message/Message";
import Help from "./pages/user-profile/HelpSection";
import Settings from "./pages/user-profile/SettingsSection";
import TaskList from "@/pages/post-list";
import TopBar from "@/components/navigation/TopBar";
import RegistrationPage from "@/pages/register/Registration";
import PublicUserProfile from "@/pages/public-profile/PublicProfile";
import Test from "./Test";
import DepositPage from "./pages/user-profile/DepositPage";

export const router = createBrowserRouter([
    {
        path: '/test',
        element: <Test />
    },
    {
        path: '/',
        element: <Home /> // this page is payment
    },
    {
        path: '/register',
        element: <RegistrationPage />
    },
    {
        path: '/login',
        element: <LogInPage />
    },

    {
        path: '/app',
        element: <TopBar />,
        children:[
            {
                path: 'messages',
                element: <Message />
            },
            {
                path: 'new-task',
                element: <CreateTask />
            },
            {
                path: 'task-list',
                element: <TaskList />
            },
            {
                path: 'task-detail/:postId',
                element: <MyTaskDetail />
            },
            {
                path: 'public-user-profile/:userId?',
                element: <PublicUserProfile />
            },
            {

                path: 'user-profile',
                element: <UserProfilePage />,
                children: [
                    {
                        path: 'deposit',
                        element: <DepositPage />
                    },
                    {
                        path: "balance",
                        element: <BalanceDetail />,
                    },
                    {
                        path: "profiledetail",
                        element: <ProfileDetail />
                    },
                    {
                        path: "settings",
                        element: <Settings />
                    },
                    {
                        path: "help",
                        element: <Help />
                    },
                ]
            }
        ]
    }

    
]);

function Routes() {
    return (
        <RouterProvider router={router} />
    );
}

export default Routes;
