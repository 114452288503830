import React from 'react';
import { MessageSquare } from 'lucide-react';
import OfferLine from './OfferLine';
function OfferList({ taskDetail }) {
    const offers = [
        { name: '马小跳', price: 99 },
        { name: '安琪儿', price: 40 },
        { name: '夏林果', price: 30 },
        { name: '丁文涛', price: 50 }
    ];

    return (
        <div>
            <h2 className="text-xl font-bold mb-4 overflow-auto">报价列表</h2>
            <div className="bg-white rounded-lg shadow">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">帮手名</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">出价</th>
                            <th scope="col" className="relative px-6 py-3">
                                <span className="sr-only">操作</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {offers?.map((offer, index) => (
                            <OfferLine key={index} offer={offer} />
                        ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default OfferList;