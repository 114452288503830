import React, { useState } from 'react';
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { Wallet, TrendingUp, CreditCard, ArrowUpRight, ArrowDownLeft, ChevronRight } from 'lucide-react';

const BalanceDetail = () => {
    const navigate = useNavigate();
    const [selectedPeriod, setSelectedPeriod] = useState('7天');

    const accountSummary = {
        balance: 3000.00,
        pendingBalance: 500.00,
        monthlyIncome: 5000.00,
        monthlyExpense: 2000.00
    };

    const recentTransactions = [
        { id: 1, type: '存入', amount: 1000.00, date: '2023-06-01', status: '已完成' },
        { id: 2, type: '提现', amount: -500.00, date: '2023-05-28', status: '处理中' },
        { id: 3, type: '购买', amount: -200.00, date: '2023-05-25', status: '已完成' },
        { id: 4, type: '存入', amount: 2000.00, date: '2023-05-20', status: '已完成' },
    ];

    return (
        <div className="w-full h-full overflow-hidden ">
            <div className='bg-white p-6 mb-10 rounded-lg w-full overflow-hidden'>
                <h1 className="text-lg font-bold text-gray-800 mb-6">账户总览</h1>
                <div className="overflow-hidden w-full grid grid-cols-1 md:grid-cols-3 gap-6 p-5 mb-8">
                    <div className="bg-white rounded-xl shadow-md p-3 transform hover:scale-105 transition-transform duration-300">
                        <div className="flex justify-between items-center mb-4">
                            <span className="text-sm font-medium text-gray-500">可用余额</span>
                            <Wallet className="text-blue-500 w-6 h-6" />
                        </div>
                        <div className="text-xl font-bold text-gray-900">${accountSummary.balance.toFixed(2)}</div>
                    </div>
                    <div className="bg-white rounded-xl shadow-md p-6 transform hover:scale-105 transition-transform duration-300">
                        <div className="flex justify-between items-center mb-4">
                            <span className="text-sm font-medium text-gray-500">本月收入</span>
                            <TrendingUp className="text-green-500 w-6 h-6" />
                        </div>
                        <div className="text-xl font-bold text-gray-900">${accountSummary.monthlyIncome.toFixed(2)}</div>
                    </div>
                    <div className="bg-white rounded-xl shadow-md p-6 transform hover:scale-105 transition-transform duration-300">
                        <div className="flex justify-between items-center mb-4">
                            <span className="text-sm font-medium text-gray-500">本月支出</span>
                            <TrendingUp className="text-red-500 w-6 h-6" />
                        </div>
                        <div className="text-xl font-bold text-gray-900">${accountSummary.monthlyExpense.toFixed(2)}</div>
                    </div>
                </div>
                <div className="flex space-x-4">
                <NavLink
                    to="../deposit"
                    className="flex-1 bg-blue-500 hover:bg-blue-600 text-white text-sm font-medium py-2 px-6 rounded-lg shadow-md transition duration-300 ease-in-out flex items-center justify-center"
                >
                    <ArrowDownLeft className="w-5 h-5 mr-2" />
                    存入
                </NavLink>
                <NavLink
                    to="../withdraw"
                    className="flex-1 bg-indigo-500 hover:bg-indigo-600 text-white text-sm font-medium py-2 px-6 rounded-lg shadow-md transition duration-300 ease-in-out flex items-center justify-center"
                >
                    <ArrowUpRight className="w-5 h-5 mr-2" />
                    提现
                </NavLink>
            </div>
            </div>
          

            <div className="bg-white rounded-xl shadow-md p-6 mb-8">
                <div className="flex justify-between items-center mb-6">
                    <h2 className="text-xl font-bold text-gray-800">近期交易</h2>
                    <div className="flex items-center space-x-2">
                        <span className="text-sm text-gray-500">周期：</span>
                        <select
                            value={selectedPeriod}
                            onChange={(e) => setSelectedPeriod(e.target.value)}
                            className="text-sm border-gray-300 rounded-md shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                        >
                            <option value="7天">7天</option>
                            <option value="1个月">1个月</option>
                            <option value="3个月">3个月</option>
                        </select>
                    </div>
                </div>
                <div className="overflow-x-auto">
                    <table className="w-full text-sm text-left text-gray-500">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 rounded-lg">
                            <tr>
                                <th className="px-6 py-3 rounded-tl-lg">类型</th>
                                <th className="px-6 py-3">金额</th>
                                <th className="px-6 py-3">日期</th>
                                <th className="px-6 py-3 rounded-tr-lg">状态</th>
                            </tr>
                        </thead>
                        <tbody>
                            {recentTransactions.map((transaction, index) => (
                                <tr key={transaction.id} className={`bg-white ${index !== recentTransactions.length - 1 ? 'border-b' : ''} hover:bg-gray-50 transition-colors duration-150 ease-in-out`}>
                                    <td className="px-6 py-4 font-medium text-gray-900">{transaction.type}</td>
                                    <td className={`px-6 py-4 font-medium ${transaction.amount > 0 ? 'text-green-600' : 'text-red-600'}`}>
                                        {transaction.amount > 0 ? '+' : '-'}${Math.abs(transaction.amount).toFixed(2)}
                                    </td>
                                    <td className="px-6 py-4 text-gray-500">{transaction.date}</td>
                                    <td className="px-6 py-4">
                                        <span className={`px-2 py-1 rounded-full text-xs ${transaction.status === '已完成' ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'
                                            }`}>
                                            {transaction.status}
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="mt-6 text-right">
                    <button
                        onClick={() => navigate('/userprofile/balance/transactionhistory')}
                        className="text-blue-500 hover:text-blue-600 text-sm font-medium inline-flex items-center transition duration-300 ease-in-out"
                    >
                        查看完整交易记录
                        <ChevronRight className="w-4 h-4 ml-1" />
                    </button>
                </div>
            </div>

            <Outlet />
        </div>

    );
};

export default BalanceDetail;