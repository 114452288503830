import { ADD_CONVERSATION, RECEIVE_CHAT_MESSAGE, SEND_CHAT_MESSAGE, SET_DIRECT_MESSAGES, SELECT_CONVERSATION, REMOVE_SELECT_CONVERSATION, READ_CHAT_MESSAGES } from "../actions/actionTypes.tsx"


const initialState = {
    conversations: [],
    selectedConversation: null,
    directMessages: {}
}

interface State {
    conversations: any;
    directMessages: {
        [senderId: string]: {
            username: string;
            messages: Message[];
        }
    };
    selectedConversation: any
}


interface Message {
    id: string;
    sender: {
        id: string
    };
    recipient: {
        id: string
    };
    content?: string;
    upload: {
        id: string
    }
    type: string;
    status: string
}

interface User {
    id: string;
    username: string
}


const chatReducer = (state: State = initialState, action: any) => {
    if (action.type === RECEIVE_CHAT_MESSAGE) {
        let message: Message = action.payload
        return receiveChatMessage(state, message)
    } else if (action.type === SEND_CHAT_MESSAGE) {
        let message = action.payload
        let userKey = message.recipient.id
        let messages = [...(state.directMessages[userKey]?.messages || []), message]

        let newDirectMessages = {
            ...state.directMessages,
            [userKey]: {
                username: state.directMessages[userKey]?.username,
                messages: messages,
            }
        }
        return {
            ...state,
            directMessages: newDirectMessages
        };
    } else if (action.type === SET_DIRECT_MESSAGES) {
        let directMessages = action.payload
        return {
            ...state,
            directMessages
        }
    } else if (action.type === SELECT_CONVERSATION) {
        return {
            ...state,
            selectedConversation: action.payload
        }
    } else if (action.type === REMOVE_SELECT_CONVERSATION) {
        return {
            ...state,
            selectedConversation: null
        }
    } else if (action.type === READ_CHAT_MESSAGES) {

        return readChatMessage(state, action.payload)

    } else if (action.type === ADD_CONVERSATION) {

        let user: User = action.payload
        console.log(user)
        let username = user.username
        let directMessages = state.directMessages
        if (!directMessages[user.id]) {
            let newDirectMessages = { ...directMessages, [user.id]: { username: username, messages: [] } }
            let newState = { ...state, directMessages: newDirectMessages }
            console.log(newState)
            return newState
        }
        return state
    } else {
        return state
    }
}

function receiveChatMessage(state: State, message: Message) {
    let senderId = message.sender.id
    let messages: Message[] = [...(state.directMessages[senderId]?.messages || []), message]
    let newDirectMessages = {
        ...state.directMessages,
        [senderId]: {
            username: state.directMessages[senderId]?.username,
            messages: messages,
        }
    }
    console.log('receive chat message',
        {
            currentState: state,
            message,
            senderId,
            newDirectMessages,
        })
    return {
        ...state,
        directMessages: newDirectMessages
    };
}

function readChatMessage(state: State, payload: any) {
    let { othersId } = payload
    let newDirectMessages = { ...state.directMessages }
    let newMessages = []
    let messages = newDirectMessages[othersId]?.messages
    if (!messages) {
        return state // no update
    }
    for (let message of messages) {
        if (message.sender.id === othersId) {
            let t = { ...message, status: 'read' }
            newMessages.push(t)
        } else {
            newMessages.push(message)
        }
    }
    newDirectMessages[othersId] = { ...newDirectMessages[othersId], messages: newMessages }
    console.log('read chat messages: ', { newDirectMessages })
    return {
        ...state,
        directMessages: newDirectMessages
    }
}

export default chatReducer