// src/store.js
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import { wsService } from '../services/WebSocketService';

const localStorageKey = 'platform'
function saveToLocalStorage(state) {
    try {
        // console.log('save the state to permanent storage')
        const serializedState = JSON.stringify(state);
        localStorage.setItem(localStorageKey, serializedState);
    } catch (e) {
        console.error("Could not save state", e);
    }
}
function loadFromLocalStorage() {
    try {
        const serializedState = localStorage.getItem(localStorageKey);
        if (serializedState === null) return undefined;
        return JSON.parse(serializedState);
    } catch (e) {
        console.error("Could not load state", e);
        return undefined;
    }
}
let currentState;

function handleChange() {
    // Assuming you want to watch changes in a specific part of the state, for example, `somePart`.
    let nextState = store.getState().somePart;

    if (nextState !== currentState) {
        currentState = nextState;
        // Save the new state to local storage because it has changed
        saveState({ somePart: currentState });
    }
}

function initWebSocket(store) {
    let state = store.getState()
    let user = state.user
    if (!wsService.user)  {
        console.log('no user in ws, inite it')
        wsService.init(store, user)
    } else if( user.userId !== wsService.user?.userId) {
        console.log('init websocket (change user)')
        wsService.init(store, user)
    } else {
        // console.log('does not need to re init web socket')
    }
}


const persistedState = loadFromLocalStorage();
console.log('persisted state', persistedState)
const store = configureStore({ reducer: rootReducer, preloadedState: persistedState });
console.log('initialized state', store.getState())

// init websocket here: 
initWebSocket(store)

store.subscribe(() => {
    initWebSocket(store)
})

store.subscribe(() => {
    saveToLocalStorage(store.getState());
});
export default store;

