import { Search } from 'lucide-react'
import UserList from './UserList.tsx'
import { useState } from 'react'


export default function ConversationHeader() {
    const [input, setInput] = useState('')
    return (
        <div className="relative p-6 bg-gradient-to-r from-indigo-500 to-purple-600">
            <h2 className="text-2xl font-bold text-white mb-4">消息</h2>
            <div className="relative">
                <input
                    type="text"
                    placeholder="搜索对话"
                    onChange={(e) => setInput(e.target.value)}
                    value={input}
                    className="w-full pl-10 pr-4 py-2 bg-white bg-opacity-20 text-white placeholder-indigo-200 border border-indigo-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-white"
                />
                <Search className="absolute left-3 top-2.5 text-indigo-200" size={20} />

            </div>
            <div className='absolute'>
                {input !== '' &&
                    <UserList closeWindow={() => setInput('')} />
                }
            </div>
        </div>
    )
}