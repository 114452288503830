import React, { useState, useEffect } from 'react';
import ConversationList from './ConversationList.tsx';
import axios from 'axios'
import Chatbox from './Chatbox';
import { useSelector, useDispatch } from 'react-redux';
import { setDirectMessages } from '@/redux/actions/chatAction';

const BACKEND_URL = import.meta.env.VITE_BACKEND_URL

export default function Message() {
    let user = useSelector((state) => state.user)
    let chat = useSelector((state) => state.chat)
    let selectedConversation = chat.selectedConversation
    let dispatch = useDispatch()

    console.log('Message state: ', {
        user, chat
    })

    useEffect(() => {
        async function initConversations() {
            let options = {
                params: {
                    userId: user.userId
                }
            }
            let response = await axios.get(`${BACKEND_URL}/api/init-conversation`, options)
            console.log('messages message map', response.data.messageMap)
            dispatch(setDirectMessages(response.data.messageMap))
        }
        initConversations()
    }, [])

    return (
        <div className="h-full py-1 lg:py-5 lg:px-10 px-3">
            <div className='h-full opacity-80 flex relative '>
                <ConversationList />
                {selectedConversation ?
                    <Chatbox
                        senderId={user.userId}
                    />
                    :
                    <div className={`flex-1 hidden lg:flex items-center justify-center text-gray-500 bg-gray-50`}>
                        <p className="text-xl font-semibold text-indigo-500">选择一个对话开始聊天</p>
                    </div>
                }
            </div>
        </div>
    );
};
