import React from 'react';
import { useNavigate } from "react-router-dom";
import { Sparkles, Book, Users, Globe, Clock, Award, MessageCircle, Zap, Target, BarChart, Video, PenTool, Code, Database } from 'lucide-react';
import useParticleBackground from '@/hooks/useParticleBackground';

const Home = () => {
    const navigate = useNavigate();
    const canvasRef = useParticleBackground()
    return (
        <div className="overflow-auto h-full bg-gradient-to-b from-blue-900 via-purple-900 to-indigo-900 text-white">
            <div ref={canvasRef} className="fixed inset-0 z-0" />
         
            <div className="relative z-10 pt-20 pb-32">
                <div className="container mx-auto px-4">
                    <h1 className="text-5xl md:text-7xl font-extrabold mb-6 animate__animated animate__fadeInDown">
                        你的24/7学习伙伴
                        <Sparkles className="inline-block ml-2 text-yellow-400" />
                    </h1>
                    <h2 className="text-3xl md:text-5xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-500 mb-8 animate__animated animate__fadeInDown">
                        即时作业帮助，专业辅导，学术讨论
                    </h2>
                    <p className="text-xl md:text-2xl mb-8 max-w-3xl">
                        我们的平台为学生提供全方位的学习支持。无论你是需要作业帮助、寻找专业辅导，还是想参与学术讨论，我们都能满足你的需求。
                    </p>
                    <p className="text-lg md:text-xl mb-12 max-w-3xl">
                        从高中到大学，从文学到理工，我们的专业团队随时为你解答疑惑，助你攻克学习难关。
                    </p>
                    <button
                        onClick={() => navigate('/register')}
                        className="bg-gradient-to-r from-pink-500 to-yellow-500 text-white font-bold py-4 px-8 rounded-full text-xl transition duration-300 transform hover:scale-105 hover:shadow-lg"
                    >
                        立即获得学习支持
                    </button>
                </div>
            </div>

            {/* Features Section */}
            <div className="relative z-10 py-20 bg-opacity-10 bg-white">
                <div className="container mx-auto px-4">
                    <h2 className="text-4xl md:text-6xl font-bold mb-16 text-center">为什么选择我们？</h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
                        {[
                            { icon: <MessageCircle size={48} />, title: "即时作业帮助", description: "遇到难题？随时提问，我们的专业团队会在最短时间内为你解答疑惑。" },
                            { icon: <Users size={48} />, title: "一对一辅导", description: "预约专业导师，获得个性化的学习指导，提高学习效率。" },
                            { icon: <Book size={48} />, title: "学习资源库", description: "海量学习资料、往年试题、学习视频，助你轻松应对各种考试和作业。" },
                            { icon: <Globe size={48} />, title: "学术讨论社区", description: "加入话题讨论，与同学们分享知识，激发学习灵感。" },
                            { icon: <Clock size={48} />, title: "24/7在线支持", description: "全天候学习支持，让你随时随地都能获得帮助。" },
                            { icon: <Award size={48} />, title: "学习成就系统", description: "记录你的学习进步，激励你不断前进。" },
                        ].map((feature, index) => (
                            <div key={index} className="bg-white bg-opacity-10 p-8 rounded-lg backdrop-filter backdrop-blur-lg">
                                <div className="text-yellow-400 mb-6">{feature.icon}</div>
                                <h3 className="text-2xl font-semibold mb-4">{feature.title}</h3>
                                <p className="text-gray-300">{feature.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* How It Works Section */}
            <div className="relative z-10 py-20">
                <div className="container mx-auto px-4">
                    <h2 className="text-4xl md:text-6xl font-bold mb-16 text-center">如何使用我们的平台</h2>
                    <div className="flex flex-col md:flex-row items-stretch justify-between">
                        {[
                            {
                                icon: <Users size={48} />,
                                title: "注册账户",
                                description: "创建你的个人学习账户，告诉我们你的学习需求和目标。",
                                details: [
                                    "填写基本学习信息",
                                    "选择你需要帮助的科目",
                                    "设置学习目标"
                                ]
                            },
                            {
                                icon: <MessageCircle size={48} />,
                                title: "提出问题",
                                description: "遇到难题？直接在平台上提问，或者预约一对一辅导。",
                                details: [
                                    "上传作业问题",
                                    "描述你的疑惑",
                                    "选择是否需要实时帮助"
                                ]
                            },
                            {
                                icon: <Book size={48} />,
                                title: "获得帮助",
                                description: "我们的专业团队会为你提供详细的解答和指导。",
                                details: [
                                    "收到专业解答",
                                    "参与互动讨论",
                                    "获得个性化学习建议"
                                ]
                            },
                            {
                                icon: <Globe size={48} />,
                                title: "参与社区",
                                description: "在学术讨论区与其他学生交流，分享知识和见解。",
                                details: [
                                    "加入感兴趣的话题",
                                    "分享你的学习心得",
                                    "与志同道合的同学交流"
                                ]
                            }
                        ].map((step, index) => (
                            <div key={index} className="flex flex-col items-center mb-12 md:mb-0 md:w-1/4 p-6 bg-white bg-opacity-10 rounded-lg backdrop-filter backdrop-blur-lg">
                                <div className="text-yellow-400 mb-6">{step.icon}</div>
                                <h3 className="text-2xl font-semibold mb-4">{step.title}</h3>
                                <p className="text-gray-300 text-center mb-6">{step.description}</p>
                                <ul className="list-disc list-inside text-gray-300">
                                    {step.details.map((detail, i) => (
                                        <li key={i} className="mb-2">{detail}</li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Subjects Section */}
            <div className="relative z-10 py-20 bg-opacity-10 bg-white">
                <div className="container mx-auto px-4">
                    <h2 className="text-4xl md:text-6xl font-bold mb-16 text-center">我们支持的学科</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
                        {[
                            { icon: <PenTool size={36} />, title: "数学", description: "从基础代数到高等数学，我们的专家团队能够帮助你解决各种数学难题。" },
                            { icon: <Zap size={36} />, title: "物理", description: "理解复杂的物理概念和解决问题，我们的物理专家随时待命。" },
                            { icon: <Code size={36} />, title: "计算机科学", description: "编程语言、算法、数据结构，让编程变得简单有趣。" },
                            { icon: <Book size={36} />, title: "文学", description: "文学分析、写作技巧、论文指导，提升你的语言表达能力。" },
                            { icon: <Globe size={36} />, title: "历史", description: "深入理解历史事件，提高论文写作和分析能力。" },
                            { icon: <Database size={36} />, title: "化学", description: "从元素周期表到有机化学，我们帮你理清化学概念。" },
                            { icon: <Target size={36} />, title: "生物学", description: "探索生命科学的奥秘，从分子生物学到生态学。" },
                            { icon: <BarChart size={36} />, title: "经济学", description: "理解微观和宏观经济学概念，提高你的经济分析能力。" },
                            { icon: <Users size={36} />, title: "心理学", description: "探索人类行为和心理过程，提高你的研究和分析能力。" },
                        ].map((subject, index) => (
                            <div key={index} className="bg-white bg-opacity-10 p-8 rounded-lg backdrop-filter backdrop-blur-lg">
                                <div className="text-yellow-400 mb-6">{subject.icon}</div>
                                <h3 className="text-2xl font-semibold mb-4">{subject.title}</h3>
                                <p className="text-gray-300">{subject.description}</p>
                                
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Testimonials Section */}
            <div className="relative z-10 py-20">
                <div className="container mx-auto px-4">
                    <h2 className="text-4xl md:text-6xl font-bold mb-16 text-center">学生反馈</h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
                        {[
                            { name: "张明", role: "大学生", comment: "这个平台真的帮了我大忙！每次遇到难题，都能很快得到解答。导师们的耐心和专业程度令人印象深刻。" },
                            { name: "李华", role: "高中生", comment: "自从使用这个平台，我的成绩有了显著提高。特别是数学和物理，现在我能够自信地解决以前觉得困难的问题了。" },
                            { name: "王芳", role: "研究生", comment: "作为一名研究生，这个平台对我的论文写作帮助很大。不仅能得到具体的指导，还学会了如何更好地进行学术研究。" },
                            { name: "刘强", role: "在职学生", comment: "工作之余还能随时获得学习帮助，这个平台太棒了！它让我能够平衡工作和学习，继续提升自己。" },
                            { name: "陈静", role: "英语学习者", comment: "通过这个平台，我克服了对英语写作的恐惧。现在我能自信地完成各种英语作业和论文了。" },
                            { name: "赵阳", role: "编程初学者", comment: "作为编程新手，我经常遇到困难。但这个平台的编程专家们总能用简单明了的方式帮我解决问题，让我学习编程变得轻松有趣。" },
                        ].map((testimonial, index) => (
                            <div key={index} className="bg-white bg-opacity-10 p-8 rounded-lg backdrop-filter backdrop-blur-lg">
                                <p className="text-lg mb-6">"{testimonial.comment}"</p>
                                <div className="flex items-center">
                                    <div className="w-12 h-12 bg-gray-300 rounded-full mr-4"></div>
                                    <div>
                                        <p className="font-semibold">{testimonial.name}</p>
                                        <p className="text-gray-300">{testimonial.role}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="relative z-10 py-20 bg-opacity-10 bg-white">
                <div className="container mx-auto px-4 text-center">
                    <h2 className="text-4xl md:text-6xl font-bold mb-8">准备好提升你的学习体验了吗？</h2>
                    <p className="text-xl md:text-2xl mb-12 max-w-3xl mx-auto">
                        加入我们的学习社区，获得即时的作业帮助，享受专业的一对一辅导，参与激发灵感的学术讨论。你的学习之旅，从这里开始！
                    </p>
                    <button
                        onClick={() => navigate('/register')}
                        className="bg-gradient-to-r from-pink-500 to-yellow-500 text-white font-bold py-4 px-8 rounded-full text-xl transition duration-300 transform hover:scale-105 hover:shadow-lg"
                    >
                        立即注册，开始学习
                    </button>
                </div>
            </div>

            {/* Community Highlights Section */}
            <div className="relative z-10 py-20">
                <div className="container mx-auto px-4">
                    <h2 className="text-4xl md:text-6xl font-bold mb-16 text-center">活跃的学习社区</h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
                        {[
                            { icon: <MessageCircle size={48} />, title: "热门讨论", description: "每周都有超过1000个新话题，涵盖各个学科的热点问题和难题解析。" },
                            { icon: <Users size={48} />, title: "学习小组", description: "加入或创建学习小组，找到志同道合的伙伴，一起攻克难关。" },
                            { icon: <Video size={48} />, title: "在线讲座", description: "定期举办各类学科的在线讲座，邀请行业专家分享见解。" },
                        ].map((feature, index) => (
                            <div key={index} className="bg-white bg-opacity-10 p-8 rounded-lg backdrop-filter backdrop-blur-lg">
                                <div className="text-yellow-400 mb-6">{feature.icon}</div>
                                <h3 className="text-2xl font-semibold mb-4">{feature.title}</h3>
                                <p className="text-gray-300">{feature.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Tutor Showcase Section */}
            <div className="relative z-10 py-20 bg-opacity-10 bg-white">
                <div className="container mx-auto px-4">
                    <h2 className="text-4xl md:text-6xl font-bold mb-16 text-center">我们的优秀导师</h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
                        {[
                            { name: "张教授", specialty: "数学", description: "在代数和微积分领域拥有20年教学经验，善于用简单的方式解释复杂概念。" },
                            { name: "李博士", specialty: "物理", description: "理论物理博士，曾参与多项国际研究项目，擅长讲解量子力学和相对论。" },
                            { name: "王老师", specialty: "计算机科学", description: "资深软件工程师，拥有丰富的实战经验，教学注重实践和应用。" },
                        ].map((tutor, index) => (
                            <div key={index} className="bg-white bg-opacity-10 p-8 rounded-lg backdrop-filter backdrop-blur-lg">
                                <div className="w-24 h-24 bg-gray-300 rounded-full mx-auto mb-6"></div>
                                <h3 className="text-2xl font-semibold mb-2 text-center">{tutor.name}</h3>
                                <p className="text-yellow-400 mb-4 text-center">{tutor.specialty}专家</p>
                                <p className="text-gray-300 text-center">{tutor.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* FAQ Section */}
            <div className="relative z-10 py-20">
                <div className="container mx-auto px-4">
                    <h2 className="text-4xl md:text-6xl font-bold mb-16 text-center">常见问题</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
                        {[
                            { question: "如何开始使用平台？", answer: "只需注册账户，选择你需要帮助的学科，然后就可以开始提问或浏览资源了。我们的团队会随时为你提供支持。" },
                            { question: "平台支持哪些学科？", answer: "我们覆盖了从高中到大学的大多数学科，包括数学、物理、化学、生物、计算机科学、文学等。如果你有特殊需求，也可以联系我们。" },
                            { question: "如何保证辅导质量？", answer: "我们所有的导师都经过严格筛选和培训。他们不仅在各自领域有深厚的专业知识，还拥有丰富的教学经验。" },
                            { question: "可以获得即时帮助吗？", answer: "是的，我们提供24/7的在线支持。你可以随时提出问题，我们会尽快为你解答。对于复杂的问题，你也可以预约一对一辅导。" },
                        ].map((faq, index) => (
                            <div key={index} className="bg-white bg-opacity-10 p-8 rounded-lg backdrop-filter backdrop-blur-lg">
                                <h3 className="text-2xl font-semibold mb-4">{faq.question}</h3>
                                <p className="text-gray-300">{faq.answer}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Final Call to Action */}
            <div className="relative z-10 py-20 bg-opacity-10 bg-white">
                <div className="container mx-auto px-4 text-center">
                    <h2 className="text-4xl md:text-6xl font-bold mb-8">开启你的学习之旅</h2>
                    <p className="text-xl md:text-2xl mb-12 max-w-3xl mx-auto">
                        不要让学习难题阻碍你的进步。加入我们，让学习变得轻松有趣！
                    </p>
                    <button
                        onClick={() => navigate('/register')}
                        className="bg-gradient-to-r from-pink-500 to-yellow-500 text-white font-bold py-4 px-8 rounded-full text-xl transition duration-300 transform hover:scale-105 hover:shadow-lg"
                    >
                        免费试用 7 天
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Home