import { receiveChatMessage } from "../redux/actions/chatAction"

let url = import.meta.env.VITE_CHAT_URL
console.log('websocket url', url)


class WebSocketService {
    constructor() {
        this.socket = null
        this.store = null
        this.user = null // record the current user authenticate information
    }

    init(store, user) {
        this.store = store
        this.user = user
        if (user.userId) {
            this.connect({ userId: user.userId })
        } else {
            console.log(
                'user does not have userId'
            )
        }
    }

    connect({ userId }) {
        this.socket = new WebSocket(url)
        this.socket.onopen = () => {
            console.log('WebSocket connected');
            setTimeout(() => {
                this.socket.send(JSON.stringify({ type: 'authenticate', user: {id: userId }}));
            }, 100);
            setInterval(() => {
                this.send(JSON.stringify({ type: 'ping' }));
            }, 10000);
        }

        this.socket.onmessage = (event) => {
            const parsedMessage = JSON.parse(event.data)
            let type = parsedMessage.type
            if (type === 'authenticate_success') {
                console.log('WebSocket Authentication successful');
                return
            } else if (type === 'chat') {
                let processedMessage = this.processMessage(parsedMessage)
                this.store.dispatch(receiveChatMessage(processedMessage))
                console.log('chat message dispatched')
            }
        }

        this.socket.onclose = () => {
            console.log('WebSocket disconnected');
            console.log('reconnect')
            this.connect({ url, userId })
        };
    }

    send(data) {
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            // console.log('web socket data', data)
            this.socket.send(JSON.stringify(data));
        }
    }

    processMessage(message) {
        let result = {
            id: message.id,
            sender: {
                id: message?.sender?.id
            },
            content: message.content,
            recipient: {
                id: message?.recipient?.id
            },
            createdAt: message.createdAt,
            status: message.status,
            upload: message.upload,
            type: message.type
        }
        return result
    }
}

export const wsService = new WebSocketService()