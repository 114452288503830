import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearUser } from '@/redux/actions/userAction';
import { motion } from 'framer-motion';
import { Home, BookOpen, List, User, LogIn, LogOut, Menu, ChevronRight, MessageCircle } from 'lucide-react';

export default function SideBar({ setOpen }) {
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();

    const variants = {
        open: { x: 0, transition: { duration: 0.3, type: 'spring', stiffness: 200 } },
        closed: { x: '-100%', transition: { duration: 0.1, stiffness: 500 } }
    };

    const handleClick = (e) => {
        e.stopPropagation();
    };

    function logout() {
        setOpen(false);
        dispatch(clearUser());
    }

    const MenuItem = ({ to, onClick, icon: Icon, children }) => (
        <motion.div
            whileHover={{ x: 5 }}
            whileTap={{ scale: 0.95 }}
        >
            <Link
                to={to}
                onClick={onClick}
                className="flex items-center justify-between text-gray-300 hover:text-white hover:bg-gray-700 rounded-lg px-4 py-3 transition duration-300"
            >
                <div className="flex items-center space-x-3">
                    <Icon size={20} />
                    <span>{children}</span>
                </div>
                <ChevronRight size={16} />
            </Link>
        </motion.div>
    );

    return (
        // <div className={`fixed z-50 inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-start`} onClick={() => setOpen(false)}>
        <div className='fixed z-50 inset-0' onClick={() => setOpen(false)}>
            <motion.div
                initial="closed"
                animate="open"
                exit={'closed'}
                variants={variants}
                onClick={handleClick}
                className="w-80 bg-gray-900 h-full overflow-y-auto shadow-2xl"
            >
                <div className="w-full h-full flex flex-col">
                    <div className="p-6 bg-gradient-to-r from-blue-600 to-purple-600">
                        <div className="flex items-center justify-between text-white mb-6">
                            <h2 className="text-2xl font-bold">青桥</h2>
                            <motion.div
                                whileHover={{ rotate: 90 }}
                                whileTap={{ scale: 0.9 }}
                            >
                                <Menu size={24} onClick={() => setOpen(false)} className="cursor-pointer" />
                            </motion.div>
                        </div>

                    </div>
                    <div className="flex-grow p-4 space-y-2">
                        <MenuItem to="/" onClick={() => setOpen(false)} icon={Home}>首页</MenuItem>
                        {user.userId ? (
                            <>
                                <MenuItem to="/app/new-task" onClick={() => setOpen(false)} icon={BookOpen}>创建作业</MenuItem>
                                <MenuItem to="/app/task-list" onClick={() => setOpen(false)} icon={List}>任务列表</MenuItem>
                                <MenuItem to="/app/messages" onClick={() => setOpen(false)} icon={MessageCircle}>消息</MenuItem>

                                <MenuItem to="/app/user-profile" onClick={() => setOpen(false)} icon={User}>个人中心</MenuItem>


                            </>
                        ) : (
                            <MenuItem to="/app/login" onClick={() => setOpen(false)} icon={LogIn}>注册/登录</MenuItem>
                        )}
                    </div>
                    {user.userId && (
                        <div className="p-4 border-t border-gray-700">
                            <MenuItem to="/" onClick={logout} icon={LogOut}>退出登录</MenuItem>
                        </div>
                    )}
                </div>
            </motion.div>
            {/* // </div> */}
        </div>
    );
}