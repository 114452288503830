import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import SingleComment from './SingleComment';

let BASE_BACKEND_URL = import.meta.env.VITE_BACKEND_URL

export default function Comments({ postId }) {
    const user = useSelector(state => state.user);
    const [reload, setReload] = useState({})
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [error, setError] = useState('')

    function refreshComments() {
        console.log('refresh comments')
        setReload({})
    }

    useEffect(() => {
        async function fetchComments() {
            try {
                const response = await axios.get(`${BASE_BACKEND_URL}/api/get-comments`, { params: { postId } });
                console.log(response)
                if (Array.isArray(response?.data?.comments)) {
                    setComments(response.data.comments);
                }
            } catch (error) {
                console.error('Error fetching comments:', error);
            }
        }
        fetchComments();
    }, [postId, reload]);

    const handleCommentSubmit = async (e) => {
        e.preventDefault();
        try {
            if (newComment) {
                const response = await axios.post(`${BASE_BACKEND_URL}/api/create-comment`, {
                    postId,
                    userId: user.userId,
                    body: newComment
                });
                console.log(response)
                setReload({})
                setNewComment('');
                setError('')

            } else {
                setError('请输入内容')
            }
        } catch (error) {
            console.error('Error submitting comment:', error);
        }
    };

    return (
        <div className="mt-8">
            <h2 className=" font-bold mb-4">讨论</h2>
            <div className="space-y-4">
                {comments.map((comment) => (
                    <SingleComment refreshComments = {refreshComments} key={comment._id} comment={comment} postId={postId} />
                ))}
            </div>
            <form onSubmit={handleCommentSubmit} className="mt-6">
                <textarea
                    className="w-full p-3 border rounded-lg resize-none"
                    rows="3"
                    placeholder="添加评论..."
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                ></textarea>
                <p className='text-red-500 text-sm'>{error}</p>
                <button type="submit" className="mt-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-300">
                    发表评论
                </button>
            </form>
        </div>
    );
}
