import { format } from 'date-fns'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import { selectConversation, setDirectMessages } from '@/redux/actions/chatAction.tsx'
import ConversationHeader from './ConversationHeader.tsx'
import { useEffect } from 'react'
const BACKEND_URL = import.meta.env.VITE_BACKEND_URL
// interface Message {
//     id: string;
//     sender: {
//         id: string
//     };
//     recipient: {
//         id: string
//     };
//     content?: string;
//     upload: {
//         id: string
//     }
//     type: string;
//     status: string
// }


interface User {
    id: string;
    username: string;
}

interface userInfoMap {
    [userId: string]: User
}

export default function ConversationList({ }) {
    let dispatch = useDispatch()
    let { directMessages, selectedConversation } = useSelector((state: any) => state.chat)
    let conversations = initConversation(directMessages)

    function countUnread(messages: any, senderId: string) {
        let count = 0
        if (messages) {
            let index = messages.length - 1
            let message = messages[index]
            while (index >= 0 && message.status !== undefined && message.status !== 'read' && message.sender.id === senderId) {
                count++
                index--
                message = messages[index]
            }
        }
        return count
    }
    // sorting needs to be done
    function initConversation(directMessages: any) {
        let result = []
        for (let userId in directMessages) {

            let { messages, username } = directMessages[userId]
            if (Array.isArray(messages)) {
                let unreadCount = countUnread(messages, userId)
                let t = {
                    userId, username, messages, unreadCount
                }
                result.push(t)
            } else {
                console.log('initConversation: messages is not array here', messages)
            }
        }
        result.sort(compareFn)
        return result
    }

    function compareFn(a: any, b: any) {

        let timeA = a?.messages[a.messages.length - 1]?.createdAt
        let timeB = b?.messages[b.messages.length - 1]?.createdAt
        if (timeA && timeB) {
            let dateA = new Date(timeA)
            let dateB = new Date(timeB)
            if (dateA < dateB) {
                return 1
            } else if (dateA > dateB) {
                return -1
            } else {
                return 0
            }
        } else if (timeA && !timeB) {
            return -1
        } else if (!timeA && timeB) {
            return 1
        } else {
            return 0
        }
    }

    console.log('ConversationList state: ', { directMessages, conversations })

    useEffect(() => {
        async function getUserInfo() {
            let missingUsers = []
            for (let userId in directMessages) {
                if (!directMessages[userId]?.username) {
                    missingUsers.push(userId)
                }
            }
            if (missingUsers.length !== 0) {
                console.log('missing users', missingUsers)
                let option = {
                    params: {
                        userIds: missingUsers
                    }
                }
                let res = await axios.get(BACKEND_URL + '/api/user-metas', option)
                console.log(res)
                let userInfoMap: userInfoMap = res.data.userInfoMap
                console.log(userInfoMap)
                let newDirectMessages = { ...directMessages }
                for (let userId in userInfoMap) {
                    let user = userInfoMap[userId]
                    let username = user.username
                    newDirectMessages[userId].username = username
                    console.log('username', username)
                }
                console.log('new Direct message:', newDirectMessages)
                dispatch(setDirectMessages(newDirectMessages))
            }
        }
        getUserInfo()
    }, [directMessages])
    return (
        <div className={`flex flex-col bg-white rounded-2xl lg:rounded-r-none lg:rounded-l-2xl overflow-hidden w-full lg:w-1/3`}>
            <ConversationHeader />
            <div className="grow overflow-y-auto overscroll-contain">
                {conversations.map((conv, index) => {
                    // console.log(conv?.messages)
                    return (
                        <div
                            key={index}
                            className={`flex items-center p-4 border-b border-gray-100 cursor-pointer transition-colors duration-150 ease-in-out ${selectedConversation === conv.userId ? 'bg-indigo-50' : 'hover:bg-gray-50'}`}
                            onClick={() => dispatch(selectConversation(conv.userId))}
                        >
                            <img src={'/defaultAvatar.jpg'} alt={conv.username} className="w-12 h-12 rounded-full object-cover mr-4" />
                            <div className="flex-1 min-w-0">
                                <div className="flex justify-between items-baseline">
                                    <h3 className="text-sm font-semibold text-gray-900 truncate">{conv.username}</h3>
                                </div>
                                {conv?.messages?.length !== 0 &&
                                    <>
                                        <p className="text-sm text-gray-600 truncate">{conv?.messages[conv.messages.length - 1].content}</p>
                                        <p className="text-xs text-gray-500">{format(new Date(conv?.messages[conv.messages.length - 1].createdAt), 'yyyy-MM-dd HH:mm:ss')}</p>
                                    </>
                                }

                            </div>
                            {conv.unreadCount > 0 && (
                                <span className="ml-2 bg-indigo-500 text-white text-xs font-medium px-2 py-0.5 rounded-full">
                                    {conv.unreadCount}
                                </span>
                            )}
                        </div>
                    )
                }
                )
                }
            </div>
        </div>
    )
}
