import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { setUser } from "@/redux/actions/userAction";
import axios from 'axios';
import { Mail, Lock } from 'lucide-react';
import useParticleBackground from '@/hooks/useParticleBackground';

const BASE_BACKEND_URL = import.meta.env.VITE_BACKEND_URL

const LogInPage = () => {
    const canvasRef = useParticleBackground()

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${BASE_BACKEND_URL}/api/login`, { email, password });
            const { token, email: email2, userId, username: username2 } = response.data;
            dispatch(setUser({ token, email: email2, userId, username: username2 || 'default username' }));
            navigate('/app/task-list');
        } catch (error) {
            console.error('There was an error!', error);
            setErrorMessage(error.response?.data?.message || '登录失败，请重试');
        }
    };

    return (
        <>
            <div ref={canvasRef} className="fixed inset-0 z-0" />
            <div className="min-h-screen bg-gradient-to-b from-blue-900 via-purple-900 to-indigo-900 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                <div className="max-w-md w-full space-y-8 bg-white bg-opacity-10 p-10 rounded-xl backdrop-filter backdrop-blur-lg">
                    <div>
                        <h2 className="mt-6 text-center text-3xl font-extrabold text-white">
                            登录你的学习账户
                        </h2>
                        <p className="mt-2 text-center text-sm text-gray-300">
                            或者{' '}
                            <Link to="/register" className="font-medium text-indigo-400 hover:text-indigo-300">
                                注册新账户
                            </Link>
                        </p>
                    </div>
                    <form className="mt-8 space-y-6" onSubmit={handleLogin}>
                        <div className="rounded-md shadow-sm -space-y-px">
                            <div className="flex items-center mb-4">
                                <Mail className="h-5 w-5 text-indigo-500 mr-2" />
                                <input
                                    id="email-address"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                    placeholder="电子邮箱"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div className="flex items-center mb-4">
                                <Lock className="h-5 w-5 text-indigo-500 mr-2" />
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="current-password"
                                    required
                                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                    placeholder="密码"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <input
                                    id="remember-me"
                                    name="remember-me"
                                    type="checkbox"
                                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                />
                                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-300">
                                    记住我
                                </label>
                            </div>

                            <div className="text-sm">
                                <a href="#" className="font-medium text-indigo-400 hover:text-indigo-300">
                                    忘记密码?
                                </a>
                            </div>
                        </div>

                        {errorMessage && (
                            <div className="text-center text-red-500 text-sm">{errorMessage}</div>
                        )}

                        <div>
                            <button
                                type="submit"
                                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                登录
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default LogInPage;