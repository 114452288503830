// this can only be used at one place.

import useParticleBackground from "../hooks/useParticleBackground"

export default function ParticleBackground() {
    let canvasRef = useParticleBackground()
    return (
        <div ref={canvasRef} className="fixed inset-0 bg-black -z-10">
        </div>
    )
}