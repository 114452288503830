import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from "./actionTypes";

export const addNotification = (message) => ({
    type: ADD_NOTIFICATION,
    payload: { id: new Date().getTime(), message },
});

export const removeNotification = (id) => ({
    type: REMOVE_NOTIFICATION,
    payload: {id},
});