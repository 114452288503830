import React, { useEffect, useState } from 'react';
import { Mail, MapPin, Link as LinkIcon, Calendar, Star } from 'lucide-react';
import axios from 'axios'
import Reviews from './Reviews';
import { useParams } from 'react-router-dom';

const PublicUserProfile = () => {
    let defaultValue = 'This user has not entered anything.'

    let { userId } = useParams()
    if (!userId) {
        return (
            <div>
                No user
            </div>
        )
    }
    const [user, setUser] = useState({})
    useEffect(() => {
        async function getData() {
            let response = await axios.get(import.meta.env.VITE_BACKEND_URL + '/api/get-user', {
                params: {
                    userId
                }
            })
            console.log(response.data)
            setUser(response.data.user)
        }
        getData()
    }, [])



    return (
        <div className="max-w-4xl mx-auto my-2 p-8 bg-white shadow-lg rounded-lg">
            {user ?
                <><div className="flex flex-col md:flex-row items-center md:items-start space-y-4 md:space-y-0 md:space-x-6 mb-8">
                    <img
                        src={user?.profile?.profilePicture || '/defaultAvatar.jpg'}
                        alt={`${user?.username}的头像`}
                        className="w-32 h-32 rounded-full object-cover border-4 border-gray-200"
                    />
                    <div className="text-center md:text-left">
                        <h1 className="text-2xl font-bold text-gray-800">{user?.profile?.fullName}</h1>
                        <p className="text-lg text-gray-600 mb-2">{user?.profile?.title}</p>
                        <p className="text-sm text-gray-500 mb-2">@{user?.username}</p>
                    </div>
                </div>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
                        <div className="col-span-2">
                            <h2 className="text-xl font-semibold mb-2 text-gray-800">关于我</h2>
                            <p className="text-gray-600 text-sm leading-relaxed">{user?.profile?.bio || defaultValue}</p>
                        </div>
                        <div>
                            <h2 className="text-xl font-semibold mb-2 text-gray-800">详细信息</h2>
                            {user?.profile ?
                                <div className="space-y-2 text-sm">
                                    <div className="flex items-center text-gray-600">
                                        <Mail size={16} className="mr-2" />
                                        <span>{user?.profile?.email}</span>
                                    </div>
                                    {user?.profile?.address &&
                                        <div className="flex items-center text-gray-600">
                                            <MapPin size={16} className="mr-2" />
                                            <span>{user?.profile?.address?.street + ' ' + user?.profile.address.city +
                                                ' ' + user.profile.address.state + ' ' + user.profile.address.country + ' ' + user.profile.address.zipCode}</span>
                                        </div>
                                    }
                                    {user?.profile?.socialMedia?.personalWebsite &&
                                        <div className="flex items-center text-gray-600">
                                            <LinkIcon size={16} className="mr-2" />
                                            <a href={user.website} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                                                {user?.profile?.socialMedia?.personalWebsite?.replace('https://', '')}
                                            </a>
                                        </div>
                                    }
                                    {user?.createdAt &&
                                        <div className="flex items-center text-gray-600">
                                            <Calendar size={16} className="mr-2" />
                                            <span>加入于 {new Date(user.createdAt).toLocaleDateString()}</span>
                                        </div>
                                    }
                                </div>
                                : <div className='text-sm text-gray-600'>
                                    {defaultValue}
                                </div>}
                        </div>
                    </div>
                    {user.skills &&
                        <div className="mb-8">
                            <h2 className="text-xl font-semibold mb-3 text-gray-800">技能</h2>
                            <div className="flex flex-wrap gap-2">
                                {user.skills.map((skill, index) => (
                                    <span key={index} className="bg-gray-100 text-gray-700 px-3 py-1 rounded-full text-sm">
                                        {skill.skillName}
                                    </span>
                                ))}
                            </div>
                        </div>
                    }
                    <Reviews />
                </>
                : <div>
                    No user
                </div>}
        </div>
    );
};

export default PublicUserProfile;
