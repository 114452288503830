import { useEffect, useRef } from 'react';
import { threeService } from '../services/ThreeBackgroundService';
const useParticleBackground = () => {
    const canvasRef = useRef();

  
    useEffect(() => {
        console.log('okok')
        threeService.init(canvasRef.current)
        return () => {     
            threeService.cleanup();
        };
    }, []);
    return canvasRef;
};

export default useParticleBackground;