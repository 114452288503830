import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Menu, Plus } from 'lucide-react';
import SideBar from './SideBar';
import { router } from '@/Routes';
import NotificationDropdown from '../NotificationDropdown';
import ProfileDropdown from '../ProfileDropdown';
import ParticleBackground from '@/components/ParticleBackground'

export default function TopBar() {
    const [open, setOpen] = useState(false);
    const user = useSelector(state => state.user);
    console.log('current login user: ', user)
    let navigate = router.navigate

    return (
        <div className='h-full overflow-hidden flex flex-col relative z-0 bg-black'>
            <ParticleBackground />
            <motion.nav
                className="h-[7vh] bg-gradient-to-r from-transparent via-indigo-500 to-transparent bg-opacity-50 text-white shadow-lg z-20"
                initial={{ y: -100 }}
                animate={{ y: 0 }}
                transition={{ type: 'spring', stiffness: 120, damping: 20 }}
            >
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex justify-between items-center h-16">
                        <div className="flex items-center space-x-4">
                            <motion.button
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                onClick={() => setOpen(true)}
                                className="p-1 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                            >
                                <Menu size={24} />
                            </motion.button>
                            <h1 className="text-2xl font-bold">
                            青<span className="text-yellow-300">桥</span>
                            </h1>
                        </div>

                        {user.userId && (
                            <div className="flex items-center space-x-4">

                                <motion.button
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                    onClick={() => navigate('/app/new-task')}
                                    className="hidden md:flex bg-green-500 text-white py-2 px-4 rounded-md cursor-pointer text-sm font-medium hover:bg-green-600 transition-colors flex items-center space-x-2"
                                >
                                    <Plus size={18} />
                                    <span>新建作业</span>
                                </motion.button>
                                <NotificationDropdown />
                                <ProfileDropdown user={user} />
                            </div>
                        )}

                        {!user.userId && (
                            <motion.button
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                onClick={() => navigate('/login')}
                                className="bg-white text-blue-600 py-2 px-4 rounded-md cursor-pointer text-sm font-medium hover:bg-blue-50 transition-colors"
                            >
                                登录 / 注册
                            </motion.button>
                        )}
                    </div>
                </div>
            </motion.nav>
            {open &&
                <div 
                className={`fixed z-50 inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-start`} 
                onClick={() => setOpen(false)}>
                </div>
            }
            <AnimatePresence>
                {open && <SideBar open={open} setOpen={setOpen} />}
            </AnimatePresence>
            <main className="grow h-[93vh] overflow-auto">
                <Outlet />
            </main>
        </div>
    );
}