// src/reducers/index.js
import { combineReducers } from 'redux';
import userReducer from './userReducer';
import notificationReducer from './notificationReducer';
import chatReducer from './chatReducer';
export default combineReducers({
    user: userReducer,
    notifications: notificationReducer,
    chat: chatReducer
});
