import { useEffect, useLayoutEffect, useRef, useState } from "react"
import { MoreVertical, ArrowLeft } from 'lucide-react';
import { useSelector, useDispatch } from "react-redux";
import { removeSelectConversation, readChatMessage } from "../../redux/actions/chatAction";
import axios from "axios";
import SingleMessage from './SingleMessage.tsx'
import SendChatbox from './SendChatbox'

const BACKEND_URL = import.meta.env.VITE_BACKEND_URL

export default function Chatbox({ senderId }) {
    let chat = useSelector((state) => state.chat)
    let user = useSelector((state) => state.user)
    // const [visibleMessages, setVisibleMessages] = useState([]);
    // const [startIndex, setStartIndex] = useState(0);
    let dispatch = useDispatch()
    let selectedConversation = chat.selectedConversation
    let messages = chat.directMessages[selectedConversation]?.messages
    let messageLength = messages?.length
    let username = chat.directMessages[selectedConversation]?.username
    let recipientId = selectedConversation
    const messagesEndRef = useRef(null);
    const containerRef = useRef(null);
    console.log('chatbox state: ', {
        recipientId, senderId, messages, user
    })
    // const prevScrollHeightRef = useRef(0);

    // const updateVisibleMessages = (newStartIndex) => {
    //     setVisibleMessages(messages.slice(newStartIndex, messages.length));
    //     setStartIndex(newStartIndex);
    // };

    // useLayoutEffect(() => {
    //     if (containerRef.current) {
    //         const newScrollHeight = containerRef.current.scrollHeight;
    //         const heightDifference = newScrollHeight - prevScrollHeightRef.current;
    //         if (heightDifference > 0) {
    //             containerRef.current.scrollTop += heightDifference;
    //         }
    //         prevScrollHeightRef.current = newScrollHeight;
    //     }
    // }, [visibleMessages]);

    // const handleScroll = () => {
    //     if (!containerRef.current) return;
    //     const { scrollTop, scrollHeight } = containerRef.current;
    //     if (scrollTop === 0 && startIndex > 0) {
    //         prevScrollHeightRef.current = scrollHeight;
    //         updateVisibleMessages(Math.max(startIndex - 5, 0));
    //     }
    // };

    const scrollToBottom = () => {
        if (containerRef.current && messagesEndRef.current) {
            const container = containerRef.current;
            const scrollElement = messagesEndRef.current;
            container.scrollTop = scrollElement.offsetTop - container.offsetTop;
        }
    };


    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    useEffect(() => {
        async function readMessages() {
            let response = await axios.post(BACKEND_URL + '/api/read-messages', {
                senderId: recipientId, recipientId: senderId
            })
        }
        readMessages()
        dispatch(readChatMessage(senderId, recipientId))
    }, [messageLength, recipientId])

    return (
        <div className={`lg:static absolute inset-0 flex-1 flex flex-col bg-white rounded-2xl lg:rounded-l-none lg:rounded-r-2xl overflow-hidden`}>
            <div className="p-4 bg-white border-b border-gray-200 flex justify-between items-center">
                <div className="flex items-center">
                    <button
                        className="lg:hidden mr-2 text-gray-600"
                        onClick={() => dispatch(removeSelectConversation())}
                    >
                        <ArrowLeft size={20} />
                    </button>
                    <img
                        src={'/defaultAvatar.jpg'}
                        alt={username}
                        className="w-10 h-10 rounded-full object-cover mr-3"
                    />
                    <h2 className="text-lg font-semibold text-gray-800">
                        {username}
                    </h2>
                </div>
                <div className="flex space-x-2">
                    <button className="text-gray-500 hover:text-indigo-600 transition-colors duration-150">
                        <MoreVertical size={20} />
                    </button>
                </div>
            </div>
            <div ref={containerRef}
                // onScroll={handleScroll}
                className="grow overflow-y-auto overscroll-contain p-4 space-y-4 bg-gray-50">
                {Array.isArray(messages) && messages.map((message) => (
                    <SingleMessage key={message.id} senderId={senderId} recipientId={recipientId} message={message} />
                ))}
                <div ref={messagesEndRef} />
            </div>
            <SendChatbox senderId={senderId} recipientId={recipientId} />
        </div>
    )
}