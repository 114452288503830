import { Send, Image as ImageIcon } from 'lucide-react';
import { wsService } from "@/services/WebSocketService";
import FilePreview from "./FilePreview.tsx";
import { v4 as uuidv4 } from 'uuid'
import { sendChatMessage } from '../../redux/actions/chatAction.tsx';
import { useState } from 'react';
import { useDispatch } from "react-redux";
import axios from 'axios';

const BACKEND_URL = import.meta.env.VITE_BACKEND_URL


export default function SendChatbox({recipientId, senderId}) {
    const [files, setFiles] = useState([])
    const [currentFileKey, setCurrentFileKey] = useState(null)
    const [uniqueId, setUniqueId] = useState(generateId())
    const [currentInboxMessage, setCurrentInboxMessage] = useState('')
    let dispatch = useDispatch()

    function generateId() {
        return 'msg-' + uuidv4()
    }

    async function processFilesUpload() {
        if (files.length !== 0) {
            const formData = new FormData();
            formData.append(`file`, files[0], files[0].name);
            formData.append('userId', senderId)
            formData.append('id', uniqueId)

            let response = await axios.post(`${BACKEND_URL}/api/upload`, formData)
            // response.data = {id, message}
            console.log(response)
            setFiles([])
            return response
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault(); // Prevents default behavior (new line)
            sendMessage();
        }
    };

    const handleFileChange = (event) => {
        const addedFiles = event.target.files; // : file[]
        console.log(event.target.value)
        // console.log(event.target)
        // console.log(addedFiles);
        if (addedFiles) {

            let newFiles = [addedFiles['0']]
            console.log(newFiles)
            setFiles(newFiles)
            setCurrentFileKey(uuidv4())
        }
        event.target.value = '';
    };
    async function sendMessage() {
        let res = await processFilesUpload()
        // console.log(res)
        let upload = null
        if (res) {
            upload = {
                id: res.data.id,
                type: 'placeholder'
            }
        }
        if (currentInboxMessage || res) {
            let currentMessageBeingSent = {
                id: uniqueId,
                type: 'chat',
                recipient: {
                    id: recipientId
                },
                sender: {
                    id: senderId
                },
                content: currentInboxMessage,
                createdAt: new Date().toISOString(),
                status: 'pending',
                upload: upload
            }
            dispatch(sendChatMessage(currentMessageBeingSent))
            wsService.send(currentMessageBeingSent)
            // console.log('message sent: ', currentInboxMessage)
            setCurrentInboxMessage('')
            setFiles([])
            setUniqueId(generateId())
        } else {
            // console.log('nothing to send')
        }
    }

    console.log('sendchatbox: ', {uniqueId})

    return (
        <div className="p-4 bg-white border-t border-gray-200 space-y-3">
            <FilePreview key={currentFileKey} file={files[0]}></FilePreview>
            <div className="flex items-center bg-gray-100  rounded-lg p-2">
                <textarea
                    type="text"
                    placeholder="输入消息..."
                    className="bg-gray-100 appearance-none flex-1 border-0 focus:border-orange-300 focus:ring-0   px-2"
                    onChange={(e) => setCurrentInboxMessage(e.target.value)}
                    value={currentInboxMessage}
                    onKeyDown={handleKeyDown}
                />
                <div className="flex items-center space-x-1">
                    <div>
                        <label htmlFor='file-upload' className="text-gray-500 hover:text-indigo-600 px-2">
                            <ImageIcon size={20} />
                            <input id="file-upload" onChange={handleFileChange} accept="image/*" type="file" className="hidden" />
                        </label>
                    </div>
                    <div>
                        <button onClick={sendMessage} className="bg-gradient-to-r from-indigo-500 to-purple-600 text-white p-2 rounded-lg hover:opacity-90 transition-opacity duration-150">
                            <Send size={20} />
                        </button>
                    </div>
                </div>

            </div>
        </div>
    )
}