import React, { useState } from 'react';
import { ChevronDown, Clock, Tag, User } from 'lucide-react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
let BACKEND_URL = import.meta.env.VITE_BACKEND_URL
function TaskHeader({ taskDetail, isOwner }) {
    let navigate = useNavigate()
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    console.log('taskDetail: ', taskDetail)



    // delete the current task
    async function handleDelete() {
        let response = await axios.post(BACKEND_URL + '/api/delete-post', { postId: taskDetail._id })
        console.log(response)
        navigate('/app/task-list')
    }

    if (!format) {
        return <div>Loading...</div>; // Show a loading state while format is being imported
    }

    return (
        <div className="bg-gradient-to-br from-blue-400 to-indigo-600 text-white px-6 py-2">
            <div className="flex justify-between items-center">
                <h1 className="text-xl font-medium">{taskDetail.title}</h1>
                {isOwner && (
                    <div className="relative z-0">
                        <button
                            onClick={() => setIsMenuOpen(!isMenuOpen)}
                            className="p-2 rounded-full hover:bg-blue-500"
                        >
                            <ChevronDown className="h-6 w-6" />
                        </button>
                        {isMenuOpen && (
                            <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                                <div className="py-1 flex flex-col" role="menu" aria-orientation="vertical">
                                    <button className="block text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">编辑</button>
                                    <button onClick={handleDelete} className="block px-4 py-2 text-sm text-left text-red-600 hover:bg-gray-100">删除</button>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
            {taskDetail.user &&
                <div className="mt-2 flex items-center text-sm">
                    <User className="h-4 w-4 mr-2" />
                    <span className="font-medium">{taskDetail.user?.username}</span>
                </div>
            }
            {taskDetail.subjectCategory &&
                <div className='text-sm mt-2'>
                    学科：{taskDetail.subjectCategory}
                </div>
            }
            <div className="mt-2 flex items-center text-sm">
                <Clock className="h-4 w-4 mr-2" />
                <span>发布时间: {format(new Date(taskDetail.createdAt), 'yyyy-MM-dd HH:mm:ss')}</span>
                <span className="mx-2">•</span>
                <span>截止日期: {format(new Date(taskDetail.deadline), 'yyyy-MM-dd HH:mm:ss')}</span>
            </div>
            {taskDetail.TaskTags &&
                <div className="mt-4 flex flex-wrap gap-2">
                    {taskDetail.TaskSkills?.map((tag, index) => (
                        <span key={index} className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-blue-200 text-blue-800">
                            <Tag className="h-4 w-4 mr-1" />
                            {tag.name}
                        </span>
                    ))}
                </div>
            }


        </div>
    );
}

export default TaskHeader;