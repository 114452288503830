import { NavLink, Outlet } from "react-router-dom"
import { useSelector } from 'react-redux'
import ParticleBackground from "../../components/ParticleBackground";
const UserProfilePage = () => {
    let user = useSelector((state) => state.user)
    console.log(user)
    return (
        <div className=" lg:w-[80%] flex flex-col w-full lg:flex-row mx-auto py-10">
            {/* <ParticleBackground /> */}
            <div className="w-full lg:basis-1/3 shrink-0 mb-4 px-4 lg:mb-0">
                <div className="pt-4 rounded-lg shadow-lg bg-white">
                    <div className="flex flex-col items-center">
                        <div className="w-32 h-32 overflow-hidden relative bg-gray-200 rounded-full flex items-center justify-center text-2xl font-semibold">
                            <img className="absolute insert-0" src="/defaultAvatar.jpg" alt="" />
                        </div>
                        <div className="mt-4">
                            <h2 className="text-xl font-semibold">用户名: {user.username}</h2>
                        </div>
                        <div className="w-full mt-4">
                            <NavLink to={"profiledetail"}><button className="text-gray-700 hover:bg-slate-100 border-b py-3 w-full">用户信息</button></NavLink>
                            <NavLink to={"balance"}><button className="text-gray-700 hover:bg-slate-100 border-b py-3 w-full">我的余额</button></NavLink>
                            <NavLink to={"settings"}><button className="text-gray-700 hover:bg-slate-100 border-b py-3 w-full">设置</button></NavLink>
                            <NavLink to={"help"}><button className="text-gray-700 hover:bg-slate-100 py-3 w-full">帮助</button></NavLink>
                        </div>
                    </div>
                </div>
            </div>
            <div className="grow px-4 lg:min-h-0">
                <Outlet />
            </div>

        </div>

    );

};

export default UserProfilePage;