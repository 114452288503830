import React, { useState } from 'react';
import { CreditCard, User, Calendar, Lock, Home, MapPin, ArrowBigLeft } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux'
import { addNotification } from '@/redux/actions/notificationAction'
let BACKEND_URL = import.meta.env.VITE_BACKEND_URL
const CardForm = () => {
    let dispatch = useDispatch()
    let user = useSelector((state) => state.user)
    let userId = user.userId
    let navigate = useNavigate()
    const [formData, setFormData] = useState({
        cardNumber: '',
        cardHolder: '',
        expiration: '',
        cvv: '',
        currency: 'usd',
        amount: null
    });
    const selections = [
        {
            text: '$5',
            amount: 500
        },
        {
            text: '$10',
            amount: 1000,
        },
        {
            text: '$20',
            amount: 2000
        },
        {
            text: '$50',
            amount: 5000
        }
    ]

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'expiration') {
            handleChangeExpir(value)
        } else if (name === 'cardNumber') {
            let newvalue = value.replace(/\D/g, '')
            if (newvalue.length > 16) {
                newvalue = newvalue.slice(0, 16)
            }
            setFormData(prevData => ({
                ...prevData,
                [name]: newvalue
            }));
        } else if (name === 'cvv') {
            let newvalue = value.replace(/\D/g, '')
            if (newvalue.length > 5) {
                newvalue = newvalue.slice(0, 5)
            }
            setFormData(prevData => ({
                ...prevData,
                [name]: newvalue
            }));
        } else {
            setFormData(prevData => ({
                ...prevData,
                [name]: value
            }));
        }
    };

    function transformExp(exp) {
        let exp_month, exp_year
        let arr = exp.split('/')
        exp_month = arr[0]
        exp_year = arr[1]
        exp_year = '20' + exp_year
        return { exp_month, exp_year }
    }

    function handleChangeExpir(value) {
        value = value.replace(/\D/g, '');
        if (value.length > 2) {
            value = value.slice(0, 2) + '/' + value.slice(2, 4);
        }
        setFormData(prevData => ({
            ...prevData,
            expiration: value
        }));
    }

    function handleSelect(selection) {
        setFormData({ ...formData, amount: selection.amount })
    }

    const validateForm = () => {
        console.log(formData)
        let newErrors = {};
        if (!formData.cardNumber.match(/^\d{16}$/)) {
            newErrors.cardNumber = "Card number must be 16 digits";
        }
        if (!formData.cardHolder) {
            newErrors.cardHolder = "Cardholder name is required";
        }

        if (!formData.cvv.match(/^\d{3,4}$/)) {
            newErrors.cvv = "CVV must be 3 or 4 digits";
        }
        if (!formData.amount) {
            newErrors.amount = '请选择金额'
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            let amount, currency, number, cvc, accountId
            amount = formData.amount
            currency = formData.currency,
                number = formData.cardNumber;
            accountId = userId
            cvc = formData.cvv;
            let { exp_month, exp_year } = transformExp(formData.expiration)
            if (amount && currency && number && cvc && exp_month && exp_year) {
                let response = await axios.post(`${BACKEND_URL}/api/payments`,
                    { amount, currency, card: { number, cvc, exp_month, exp_year }, accountId })
                console.log("FormData:", { amount, currency, card: { number, cvc, exp_month, exp_year }, accountId });

                console.log(response)
                dispatch(addNotification('付款正在处理中，请检查状态以确认'))
                navigate(-1)
            } else {
                console.log('not submitted: amount, currency, card are missing')
                console.log({ amount, currency, number, cvc, exp_month, exp_year })
            }
        }
    };

    return (
        <div className=" flex flex-col">

            <div className="w-full bg-white p-8 rounded-lg">
                <Link className='flex hover:text-orange-400 mb-2' to={-1}>
                    <ArrowBigLeft />
                    <span>返回</span>
                </Link>
                <div>
                    <h2 className="mb-3 text-xl font-bold text-gray-700">
                        充值
                    </h2>
                    <div className='flex space-x-3 '>
                        {selections.map((selection, index) =>
                            <button key={index} onClick={() => handleSelect(selection)}
                                className={`${selection.amount === formData.amount && 'bg-gray-300'} select-none hover:bg-gray-200 px-3 py-1 
                                    rounded-lg shadow-2xl`}>
                                {selection.text || 'Text'}
                            </button>
                        )}
                    </div>
                    {errors.amount && <p className="mt-2 text-sm text-red-600">{errors.amount}</p>}

                </div>
                <form onSubmit={handleSubmit} className="mt-4 flex flex-col">
                    <div className="mb-4">
                        <div className="mb-4">
                            <label htmlFor="cardNumber" className="sr-only">Card Number</label>
                            <div className="relative flex">
                                <div className="z-10 absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <CreditCard className="h-5 w-5 text-gray-500" />
                                </div>
                                <input
                                    id="cardNumber"
                                    name="cardNumber"
                                    type="text"
                                    required
                                    className="z-0 appearance-none rounded-md relative block w-full px-3 py-2 pl-10 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    placeholder="Card Number"
                                    value={formData.cardNumber}
                                    onChange={handleChange}
                                />
                            </div>
                            {errors.cardNumber && <p className="mt-2 text-sm text-red-600">{errors.cardNumber}</p>}
                        </div>
                        <div className="mb-4">
                            <label htmlFor="cardHolder" className="sr-only">Card Holder</label>
                            <div className="relative flex">
                                <div className="absolute z-20 inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <User className="h-5 w-5 text-gray-400" />
                                </div>
                                <input
                                    id="cardHolder"
                                    name="cardHolder"
                                    type="text"
                                    required
                                    className="appearance-none rounded-md relative block w-full px-3 py-2 pl-10 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    placeholder="Card Holder Name"
                                    value={formData.cardHolder}
                                    onChange={handleChange}
                                />
                            </div>
                            {errors.cardHolder && <p className="mt-2 text-sm text-red-600">{errors.cardHolder}</p>}
                        </div>
                        <div className="flex">
                            <div className="w-1/2 pr-2">
                                <label htmlFor="expirationMonth" className="sr-only">Expiration Month</label>
                                <div className="relative">
                                    <div className="absolute z-20 inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <Calendar className="h-5 w-5 text-gray-400" />
                                    </div>
                                    <input
                                        id="expiration"
                                        name="expiration"
                                        type="text"
                                        required
                                        className="appearance-none rounded-md relative block w-full px-3 py-2 pl-10 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        placeholder="MM/YY"
                                        value={formData.expiration}
                                        onChange={handleChange}
                                    />
                                </div>
                                {errors.expirationMonth && <p className="mt-2 text-sm text-red-600">{errors.expirationMonth}</p>}
                            </div>
                            <div className='w-1/2 pl-2'>
                                <label htmlFor="cvv" className="sr-only">CVV</label>
                                <div className="relative flex">
                                    <div className="absolute z-20 inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <Lock className="h-5 w-5 text-gray-400" />
                                    </div>
                                    <input
                                        id="cvv"
                                        name="cvv"
                                        required
                                        className="appearance-none relative block w-full px-3 py-2 pl-10 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                        placeholder="CVV"
                                        value={formData.cvv}
                                        onChange={handleChange}
                                    />
                                </div>
                                {errors.cvv && <p className="mt-2 text-sm text-red-600">{errors.cvv}</p>}
                            </div>
                        </div>

                    </div>
                    {/* <div className="rounded-md shadow-sm mt-8">
                        <h3 className="text-lg font-medium text-gray-900 mb-4">Billing Information</h3>

                        <div className="mb-4">
                            <label htmlFor="billingName" className="block text-sm font-medium text-gray-700 mb-1">
                                Full Name
                            </label>
                            <div className="relative rounded-md shadow-sm">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <User className="h-5 w-5 text-gray-400" />
                                </div>
                                <input
                                    type="text"
                                    name="billingName"
                                    id="billingName"
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                                    placeholder="Full Name"
                                    value={formData.billingName}
                                    onChange={handleChange}
                                />
                            </div>
                            {errors.billingName && <p className="mt-2 text-sm text-red-600">{errors.billingName}</p>}
                        </div>

                        <div className="mb-4">
                            <label htmlFor="billingAddress" className="block text-sm font-medium text-gray-700 mb-1">
                                Address
                            </label>
                            <div className="relative rounded-md shadow-sm">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <Home className="h-5 w-5 text-gray-400" />
                                </div>
                                <input
                                    type="text"
                                    name="billingAddress"
                                    id="billingAddress"
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                                    placeholder="Address"
                                    value={formData.billingAddress}
                                    onChange={handleChange}
                                />
                            </div>
                            {errors.billingAddress && <p className="mt-2 text-sm text-red-600">{errors.billingAddress}</p>}
                        </div>

                        <div className="mb-4">
                            <label htmlFor="billingCity" className="block text-sm font-medium text-gray-700 mb-1">
                                City
                            </label>
                            <div className="relative rounded-md shadow-sm">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <MapPin className="h-5 w-5 text-gray-400" />
                                </div>
                                <input
                                    type="text"
                                    name="billingCity"
                                    id="billingCity"
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                                    placeholder="City"
                                    value={formData.billingCity}
                                    onChange={handleChange}
                                />
                            </div>
                            {errors.billingCity && <p className="mt-2 text-sm text-red-600">{errors.billingCity}</p>}
                        </div>

                        <div className="flex mb-4 -mx-2">
                            <div className="w-1/2 px-2">
                                <label htmlFor="billingState" className="block text-sm font-medium text-gray-700 mb-1">
                                    State
                                </label>
                                <div className="relative rounded-md shadow-sm">
                                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <MapPin className="h-5 w-5 text-gray-400" />
                                    </div>
                                    <input
                                        type="text"
                                        name="billingState"
                                        id="billingState"
                                        className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                                        placeholder="State"
                                        value={formData.billingState}
                                        onChange={handleChange}
                                    />
                                </div>
                                {errors.billingState && <p className="mt-2 text-sm text-red-600">{errors.billingState}</p>}
                            </div>
                            <div className="w-1/2 px-2">
                                <label htmlFor="billingZip" className="block text-sm font-medium text-gray-700 mb-1">
                                    ZIP Code
                                </label>
                                <div className="relative rounded-md shadow-sm">
                                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <MapPin className="h-5 w-5 text-gray-400" />
                                    </div>
                                    <input
                                        type="text"
                                        name="billingZip"
                                        id="billingZip"
                                        className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                                        placeholder="ZIP Code"
                                        value={formData.billingZip}
                                        onChange={handleChange}
                                    />
                                </div>
                                {errors.billingZip && <p className="mt-2 text-sm text-red-600">{errors.billingZip}</p>}
                            </div>
                        </div>

                        <div className="mb-4">
                            <label htmlFor="billingCountry" className="block text-sm font-medium text-gray-700 mb-1">
                                Country
                            </label>
                            <div className="relative rounded-md shadow-sm">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <MapPin className="h-5 w-5 text-gray-400" />
                                </div>
                                <input
                                    type="text"
                                    name="billingCountry"
                                    id="billingCountry"
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                                    placeholder="Country"
                                    value={formData.billingCountry}
                                    onChange={handleChange}
                                />
                            </div>
                            {errors.billingCountry && <p className="mt-2 text-sm text-red-600">{errors.billingCountry}</p>}
                        </div>
                    </div> */}
                    <div>
                        <button
                            type="submit"
                            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            支付
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );

};

export default CardForm;