import { useEffect, useState } from "react"
import axios from "axios";
import { addConversation } from "../../redux/actions/chatAction";
import {useDispatch} from 'react-redux'
const BACKEND_URL = import.meta.env.VITE_BACKEND_URL
interface User {
    id: string;
    username: string;
}

export default function UserList({closeWindow} : {closeWindow: any}) {
    const [users, setUsers] = useState<User[]>([])
    const dispatch = useDispatch()
    useEffect(() => {
        async function getUsers() {
            let res = await axios.get(BACKEND_URL + '/api/users')
            let users: User[] = res.data
            setUsers(users)
        }
        getUsers()
    }, [])

    function onClick(user: User) {
        dispatch(addConversation(user))
        closeWindow()
    }
    return (
        <div className="flex flex-col h-48 overflow-auto">
            {users.map((user) =>
                <div onClick={() => onClick(user)} key={user.id} className=" bg-white border p-2">
                    {user.username}
                </div>
            )}
        </div>
    )
}