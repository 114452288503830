import { useState, useEffect, useRef } from "react";
import axios from 'axios'
interface Props {
    upload: {
        id: string;
        url: string;
        type: string
    };
}
const BACKEND_URL = import.meta.env.VITE_BACKEND_URL

export default function RenderFile({ upload }: Props) {
    const [open, setOpen] = useState(false)
    const [inView, setInView] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const [url, setUrl] = useState('')

    useEffect(() => {
        async function getUrl() {
            let res = await axios.get(`${BACKEND_URL}/api/upload/${upload.id}`)
            let url = res.data.url
            setUrl(url)
        }
        if(!upload.url) {
            getUrl()
        } else {
            setUrl(upload.url)
        }
    }, [])

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    console.log('I am called')
                    setInView(true);
                    observer.disconnect();
                }
            },
            { threshold: 0.1 }
        );

        if (containerRef.current) {
            observer.observe(containerRef.current);
        }

        return () => {
            if (containerRef.current) {
                observer.unobserve(containerRef.current);
            }
        };
    }, []);

    // console.log(file)
    return (
        <div ref={containerRef} className="w-full h-full">
            {inView &&
                // ?
                <img onClick={() => setOpen(true)} src={url || ''} loading="lazy" decoding="async" alt="File preview" className="w-full h-full object-cover" />
                // :
                // <div onClick={() => setOpen(true)} className="w-full h-full shadow-lg flex justify-center items-center text-blue-500 text-lg " >点击查看</div>
            }
            {open && <LargeRender close={() => setOpen(false)} url={url} />
            }
        </div>
    )
}

function LargeRender({ url, close }: { url: string, close: any }) {
    return (
        <div onClick={() => close()} className="absolute inset-0 bg-black z-[51] flex justify-center items-center p-1">
            <img src={url || ''} className="object-contain h-full w-full" alt="File preview" />
        </div>
    )
}